

export default function Label({ name, afiProvider }) {
  const afi = afiProvider.getAfi(name);

  return (
    <label className={afi.display.ui_type == "button" ? "d-none" : "form-label"}
      htmlFor={afi.name} >
      {afi.display.input_label}{afi.display.required ? ": *" : ":"}
    </label>);
}
