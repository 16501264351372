
import React, { useState, useEffect, useMemo } from "react";
import { Location, useNavigate } from 'react-router-dom';
import { Chart } from "react-google-charts";
import moment from 'moment';

import { FieldValidation, ExportCsvFile } from 'common/functions';
import MemberService from 'api/agency/MemberService';
import Table from "control/afi/element/Table";
import appConstant from "constant/index";


// https://www.react-google-charts.com/examples
export default function Dashboard (props) {
  const navigation = useNavigate();
  const { getProfile, logout } = props.useSession();
  const [user, setUser] = useState(getProfile());

  const [missionList, setMissionList] = useState([]);
  const [missionSummary, setMissionSummary] = useState([]);
  const [redemptionSummary, setRedemptionSummary] = useState({});

  const [dateFrom, setDateFrom] = useState(moment().format('YYYY-MM-DD'));
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));

  const [search, setSearch] = useState({
    agency: "",
    journey: ""
  });
  const [searchResult, setSearchResult] = useState();

  useEffect(() => {
    if (user.user_level_id < 3) {
      navigation(appConstant.DOCUMENT_ROOT);
    }
  }, []);

  const printPage = () => {
    window.print();
  }

  const handleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const val = e.target.value;
    setSearch({ ...search, [e.target.name]: val });
  }

  useEffect(() => {
    document.title = 'Dashboard';
    MemberService.getMissionSummary()
      .then((res) => {
        if (res.data.result == 1) {
          setMissionSummary(res.data.data);
        }
      });
    MemberService.getRedemptionSummary()
      .then((res) => {
        if (res.data.result == 1) {
          setRedemptionSummary(res.data.data);
        }
      });
    MemberService.getMissionList()
      .then((res) => {
        if (res.data.result == 1) {
          setMissionList(res.data.data);
        }
      });
  }, []);

  const handleSearch = (e) => {
    e.stopPropagation();

    if (search.agency == "" && search.journey == "") {
      alert("Agency name/Journey name are required.");
      return;
    }
    setSearchResult([]);
    MemberService.search(search)
      .then((res) => {
        if (res.data.result == 1) {
          setSearchResult(res.data.data);
        }
      });
  }

  const handleExport = (e) => {
    e.stopPropagation();
    if (searchResult && searchResult.length > 0) {
      const header = { representation: "Agency", jourey_date: "Journey Date", journey: "Journey Name" };
      let data = [...searchResult];
      data.shift();
      ExportCsvFile(header, data, "agency_journey_export");
    }
  }

  // Building plot data
  const missionChartData = useMemo(() => {
    let data = [
      ["Journey", "Count"],
    ];
    missionSummary.map((item) => {
      data.push([item.name, item.journeyCount]);
    })
    return data;
  });

  return (
    <section className="mt-4 p-2">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSummary">
            <button class="accordion-button" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseSummary"
              aria-expanded="false" aria-controls="collapseSummary">
              Summary
            </button>
          </h2>

          <div id="collapseSummary" class="accordion-collapse collapse show"
            aria-labelledby="headingSummary" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div className="card card-body mt-2">
                <div className="row">
                  <div className="col-6">
                    <h5 id="journey-summary" className="card-header text-center">Redemptions</h5>
                    <table className="table">
                      <tbody id="tbody_redemption">
                        <tr>
                          <td>Points Issued</td>
                          <td>{redemptionSummary.points_issued}</td>
                        </tr>
                        <tr>
                          <td>Points Redeemed</td>
                          <td>{redemptionSummary.points_redeemed}</td>
                        </tr>
                        <tr>
                          <td>Points Unclaimed</td>
                          <td>{redemptionSummary.points_unclaimed}</td>
                        </tr>
                        <tr>
                          <td>Usage %</td>
                          <td>{redemptionSummary.usage_percent}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="col-6">
                    <Chart
                      chartType="PieChart"
                      data={[["Action", "Points"], ["Redeemed", redemptionSummary.points_redeemed], ["Unclaimed", redemptionSummary.points_unclaimed]]}
                      width="100%"
                      height="280px"
                      legendToggle={false}
                      options={{
                        chartArea: { width: "90%" }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="card card-body mt-1">
                <div className="">
                  <Chart
                    chartType="ColumnChart"
                    data={missionChartData}
                    width="100%"
                    height="400px"
                    legendToggle={false}
                    options={{
                      chartArea: { width: "90%" }
                    }}
                  />
                </div>

                <h5 id="journey-summary" className="card-header text-center mt-2">Journeys</h5>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Journey Name</th>
                      <th>Count</th>
                    </tr>
                  </thead>
                  <tbody id="tbody_journey">
                    {
                      missionSummary && missionSummary.map((item) => (
                        <tr key={"journey-" + item.id}>
                          <td>{item.name}</td>
                          <td>{item.journeyCount}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        </div>


        <div class="accordion-item">
          <h2 class="accordion-header" id="headingDetail">
            <button class="accordion-button" type="button"
              data-bs-toggle="collapse" data-bs-target="#collapseDetail"
              aria-expanded="false" aria-controls="collapseDetail">
              Details
            </button>
          </h2>
          <div id="collapseDetail" class="accordion-collapse collapse"
              aria-labelledby="headingDetail" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              
              <div className="row py-3 justify-content-start d-none">
                <div className="col-3">
                  <input id="date_from" name="date_from" type="date" className="form-control" value={dateFrom} />
                </div>
                <div className="col-3">
                  <input id="date_to" name="date_to" type="date" className="form-control" value={dateTo} />
                </div>
                <div className="col-6">
                  <button id="" name="" type="button" className="btn btn-primary me-2" onClick={handleSearch}>Search</button>
                  <button id="" name="" type="button" className="btn btn-primary" onClick={handleExport}>Export</button>
                </div>
              </div>

              <div className="row py-3 justify-content-start">
                <div className="col-sm-10 col-lg-3">
                  <label className="" htmlFor="agency">Agency name</label>
                  <input id="agency" name="agency" type="text" className="form-control" value={search.agency}
                    onChange={handleChange} placeholder="Name starts with" />
                </div>
                <div className="col-sm-10 col-lg-3">
                  <label className="" htmlFor="journey">Journey</label>
                  <select id="journey" name="journey" className="form-control" value={search.journey} onChange={handleChange}>
                    <option value=""> -- Select -- </option>
                    {
                      missionList && missionList.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                </div>
                <div className="col-sm-10 col-lg-6">
                  <br />
                  <button id="btn-search" name="btn-search" type="button" className="btn btn-primary me-2" onClick={handleSearch}>Search</button>
                  <button id="btn-export" name="btn-export" type="button" className="btn btn-primary" onClick={handleExport}>Export</button>
                </div>
              </div>
              
              <div id="">
                {
                  searchResult && <Table data={searchResult} handleRowClick={null} />
                }
              </div>

            </div>
          </div>

        </div>
      </div>

    </section>
  )
}
