import React, { useState, useEffect, useMemo } from "react";

import FabricPreview from "control/afi/template/FabricPreview";
import Graphic from "control/afi/element/Graphic";
import Select from "control/afi/element/Select";
import ImageList from "control/afi/element/ImageList";
import Number from "control/afi/element/Number";
import Tel from "control/afi/element/Tel";
import Email from "control/afi/element/Email";
import Url from "control/afi/element/Url";
import Date from "control/afi/element/Date";
import Text from "control/afi/element/Text";
import PdfPreview from "control/afi/template/PdfPreview"
import moment from 'moment';


export default function CustomizeFabric({ product, adors, getAdor, updateAdorValue }) {

  const [validate, setValidate] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const handleOpenModel = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Change forcast 
  useEffect(() => {
    // Find listener then trigger action


  }, [adors]);

  const handleSubmit = () => {
    // broadcast validation
    //debugger;
    setValidate((prev) => prev + 1);
    let pass = true;
    handleOpenModel();
    return;

    for (let key in adors) {
      pass = pass && adors[key].is_invalid !== 1;
    }
    if (pass) {
      alert("Pass validation");
    }
  }

  const adorForm = useMemo(() => {
    // debugger;
    if (adors) {
      let rv = [];
      for (let key in adors) {
        const ador = adors[key];
        if (ador.ui_type == "graphic") {
          rv.push(<Graphic ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "custom_list") {
          rv.push(<Select ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "user_address_list") {
          rv.push(<Select ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "department_address_list") {
          rv.push(<Select ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "image_list") {
          rv.push(<ImageList ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "number") {
          rv.push(<Number ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "tel") {
          rv.push(<Tel ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "email") {
          rv.push(<Email ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "url") {
          rv.push(<Url ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else if (ador.ui_type == "date") {
          rv.push(<Date ador={ador} updateAdorValue={updateAdorValue} />);
        }
        else {
          rv.push(<Text ador={ador} updateAdorValue={updateAdorValue} />);
        }
      }
      return rv;
    }
  }, [adors]);

  return (
    <div className="p-2">
      <h2 className="p-2 text-center">Customize {product.name} </h2>
      <div className="row px-2 ">
        <div className="col-8">
          <FabricPreview adors={adors} updateAdorValue={updateAdorValue} />
        </div>

        <div className="col-4">
          {/* adorForm */}
          <div className="px-2 mt-2 text-center">
            <button type="button" className="btn btn-primary" onClick={handleSubmit} >Submit</button>
          </div>
        </div>

      </div>

    </div>
  );
}
