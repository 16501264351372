import { useState } from "react";

/**
 * Display afi custom list, self pull data from list_name
 * @param {*} afi - {
 * }
 * @param [{*}] options - Select options
 */
export default function Select({ name, options, setformChanged, afiProvider }) {
  
  const afi = afiProvider.getAfi(name);
  const [userVal, setUserVal] = useState();

  const handleChange = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    // Check required with afi.is_required
    setUserVal(ev.target.value);

    // Check format with afi.validation
    const triggers = afiProvider.updateAfiValue(ev.target.name, "user_value", ev.target.value);
    debugger;
    if (triggers.length > 0) {
      setTimeout(() => setformChanged(afi.name), 800);
    }

  }

  return afi && (
    <select name={afi.name}
      id={afi.name}
      className={afi.display.class ? afi.display.class : "form-control"}
      value={userVal}
      onChange={handleChange}
      readOnly={afi.display.readonly}
      required={afi.display.required}
      disabled={afi.display.disabled}
    >
      <option value=""> </option>
      {
        options && options.map((item, idx) => (
          <option key={idx} value={item.id}>{item.name}</option>
        ))
      }
    </select>
  );
}
