import { useState, useEffect, useRef } from "react";
import appConstant from "constant/index";


export default function HtmlPreview ({ product, page }) {
  
  // const { editor, onReady } = useFabricJSEditor()
  const myCanvas = useRef(null);

  const [lastAdorImg, setLastAdorImg] = useState();

  // Drawing adors elements on to a canvas
  useEffect(() => {
    const ctx = myCanvas.current.getContext('2d');

    if (page == 1) {
      const bg = new Image();
      bg.src = appConstant.DOCUMENT_ROOT + "/dist/img/product/sample_front.jpg";
      bg.onload = () => {
        drawBgImage(bg, ctx);

        ctx.textAlign = "center";
        ctx.font = "20px Arsenal";
        if (product.pet_tag_name) {
          ctx.fillText(product.pet_tag_name, 87, 102);
        }
        if (product.pet_tag_line1) {
          ctx.fillText(product.pet_tag_line1, 87, 124);
        }
        if (product.pet_tag_line2) {
          ctx.fillText(product.pet_tag_line2, 87, 146);
        }
      }
    }
    else {
      const bg = new Image();
      bg.src = appConstant.DOCUMENT_ROOT + "/dist/img/product/sample_back.jpg";
      bg.onload = () => {
        drawBgImage(bg, ctx);
      }
    }

  });

  // Drawing adors elements on to a canvas
  const drawBgImage = (img, ctx) => {
    let canvas = ctx.canvas;
    let hRatio = canvas.width / img.width;
    let vRatio = canvas.height / img.height;
    let ratio = Math.min(hRatio, vRatio);
    let translate_x = (canvas.width - img.width * ratio) / 2;
    let translate_y = (canvas.height - img.height * ratio) / 2;

    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    // For jpeg white background
    ctx.fillStyle = "#FFFFFF";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(img,
      0, 0, img.width, img.height,
      translate_x, translate_y, img.width * ratio, img.height * ratio);
  }

  const drawText = (line) => {
    const ctx = myCanvas.current.getContext('2d');
    ctx.font = "50px Arial";
    
    ctx.fillText("Hello World", 10, 80);
  }




  return (
    <canvas ref={myCanvas} id="my-canvas" width="175" height="206"></canvas>
  );

}
