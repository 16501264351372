import axios from "axios";

export default () => {
  let token = null;
  const profile = sessionStorage.getItem("profile");
  if (profile) {
    token = JSON.parse(profile).token;
    // console.log(token);
  }

  return axios.create({
    baseURL: "https://petinsurancerewards.com/agencyjourney/api",
    // baseURL: "https://staging.petinsurancerewards.com/agencyjourney/api",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  });
}