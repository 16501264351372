import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Summary from "./Summary";
import Product from "./Product";
import Redeem from "./Redeem";
import appConstant from "constant/index";
import { toast } from "react-toastify";

function Rewards(props) {
  // debugger;
  const location = useLocation();
  const navigate = useNavigate();

  const { getProfile } = props.useSession();

  const [current, setCurrent] = useState("summary");
  const [user, setUser] = useState(getProfile());


  const totals = useMemo(() => {
    let summary = {
      earned: 0,
      redeemed: 0,
      total_point: 0
    };

    user && user.journeys && user.journeys.map((reward) => {
      if (reward.journey_id > 2 && reward.journey_id < 20) {
        summary.earned += reward.points;
        summary.total_point += reward.points;
      }
      else if (reward.journey_id == 20) {
        summary.redeemed = reward.points;
        summary.total_point -= reward.point;
      }
    });

    if (summary.total_point < 0) {
      summary.total_point = 0;
    }

    // Debug only 
    // summary.total_point = 2000;
    return summary;
  }, [user]);

  // debugger
  useEffect(() => {
    document.title = 'Rewards';
  }, []);

  const handleImageError = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };


  return (
    <section className="mt-4 px-5" style={{ "minHeight": "85vw" }}>
      {
        location.pathname == "/agencyjourney/history" &&
        <Summary user={user} totalPoint={totals} />
      }
      {
        location.pathname == "/agencyjourney/redeem" &&
        <Product user={user} totalPoint={totals} />
      }
      {
        location.pathname == "/agencyjourney/checkout" &&
        <Redeem user={user} totalPoint={totals} />
      }
    </section>
  );

}
export default Rewards;
