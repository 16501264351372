import http from "./Endpoint";
import appConstant from "constant";

class MemberService {

  support(article) {
    return http().post("/support", article);
  }


  /**
   * 
   * @param {*} data = {
   *   "name": "Kenny Trinh",
   *   "company": "Precision services Group",
   *   "line1": "15201 Woodlawn ave",
   *   "line2": "",
   *   "city": "Irvine",
   *   "state": "CA",
   *   "province": null,
   *   "zip_code": "92780",
   *   "country": "US"
   * }
   * @returns 
   */
  validateAddress(data) {
    return http().post("/validateAddress", data);
  }
  
  query(data) {
    let article = {
      "filter": {
        "collection": "members",
        "date_from": data.date_from,
        "date_to": data.date_to,
        // One of created, email_landed, email_quoted, email_submited
        // text_landed, text_quoted, text_submited
        "event": data.event
        // "email": "kennyt@psggo.com",
        // "first_name": "Kenny",
        // "status": "active"
      },
      "return": [
        "rewards",
        "communication"
      ]
    };
    return http().post("/query", article);
  }

  report(data) {
    let article = {
      "filter": {
        "campaign": "AGENCY_PAWSIBLE",
        "collection": "members",
        "date_from": "2023-08-01",
        "date_to": "2023-09-01"
      },
      "return": [
        // Overall summary
        "overall",
        // 60 days activities
        "activities",
        // Landing
        "landing"
      ]
    };
    return http().post("/report", article);
  }

  getUtm(article) {
    return http().post("/getUtm", article);
  }

  /**
   * Get promotions for a user based on current date
   * @param {*} id 
   * @returns 
   */
  promotions(userId) {
    return http().post("/promotions", { "_id": userId });
  }

  getMissionList() {
    return http().post("/getMissionList", {});
  }

  getMissionSummary() {
    return http().post("/getMissionSummary", {});
  }

  getRedemptionSummary() {
    return http().post("/getRedemptionSummary", {});
  }

  /**
   * Get account information for a user
   * @param {*} id 
   * @returns 
   */
  account(id) {
    const article = {
      filter: {
        user_id: id
      }
    };
    return http().post("/account", article);
  }

  /**
   * Place order
   * @returns 
   */
  placeOrder(article) {
    return http().post("/placeOrder", article);
  }

  getMissionList() {
    return http().post("/getMissionList", {});
  }


  search(searchData) {
    const article = {
      "filter": searchData
    }
    return http().post("/search", article);
  }


  listProducts(article) {
    return http().post("/listProducts", { "filter": article });
  }

}

export default new MemberService();