import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Search, Printer, Person, List } from "react-bootstrap-icons";
import appConstant from "constant/index";
import { useSession } from "context/SessionContext";
import MemberService from "api/agency/MemberService";


export default function Header({ useSession }) {

  const location = useLocation();
  const { isLogin, logout, getProfile } = useSession();
  const [user, setUser] = useState(getProfile());

  const handleSignOut = (e) => {
    e.stopPropagation();
    e.preventDefault();

    logout();
    document.location = appConstant.DOCUMENT_ROOT;
  };

  const printPage = () => {
    window.print();
  };

  return (
    <header className="no-print sticky-top bg-white mt-2">
      <div className="container-fluid">
        <div className="row">
          
          {isLogin() && (
            <div className="col-1">
              <nav className="navbar">
                <a
                  className=""
                  type="button"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  style={{
                    fontSize: "18px",
                    marginTop: "20px",
                    marginLeft: "35px",
                  }}
                >
                  <span className="navbar-toggler-icon"></span>
                </a>

                <ul
                  className="dropdown-menu vh-100 bg-grey"
                  style={{
                    borderRadius: "0",
                    width: "280px",
                    marginTop: "21px",
                  }}
                >
                  <li className="fs-3 py-2 text-center">
                    <Link
                      className="dropdown-item text-blue py-3"
                      to={appConstant.DOCUMENT_ROOT}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="fs-3 py-2 text-center">
                    <Link
                      className="dropdown-item text-blue py-3"
                      to={appConstant.DOCUMENT_ROOT + "/history"}
                    >
                      History
                    </Link>
                  </li>
                  <li className="fs-3 py-2 text-center">
                    <Link
                      className="dropdown-item text-blue py-3"
                      to={appConstant.DOCUMENT_ROOT + "/redeem"}
                    >
                      Redeem
                    </Link>
                  </li>
                  {
                    user.user_level_id == 3 && 
                    <li className="fs-3 py-2 text-center">
                      <Link
                        className="dropdown-item text-blue py-3"
                        to={appConstant.DOCUMENT_ROOT + "/dashboard"}
                      >
                        Dashboard
                      </Link>
                    </li>
                  }
                  <li className="fs-3 py-2 text-center">
                    <a
                      className="dropdown-item text-blue py-3"
                      onClick={handleSignOut}
                    >
                      Sign out
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          ) 
          }

          <div className="no-vs-search col justify-content-center">
            <div className="text-center">
              <img
                src={appConstant.DOCUMENT_ROOT + "/dist/img/log.png"}
                alt="Nationwide"
                className=""
                style={{ width: "180px" }}
                />
            </div>
          </div>


        </div>
      </div>
      {location.pathname !== appConstant.DOCUMENT_ROOT && location.pathname !== appConstant.DOCUMENT_ROOT + "/" ? (
        <div className="mx-4 mt-3" style={{ borderBottom: "1px solid #000" }} />
      ) : (
        <div className="mx-4 mt-3" style={{ border: "none" }} />
      )}

    </header>
  );
}

