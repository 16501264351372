import React, { useState, useEffect } from "react";
import Support from "components/support/Support";

function Footer({ useSession, version }) {

  const {  getProfile } = useSession();
  const [user, setUser] = useState(getProfile());

  const [showModal, setShowModal] = useState(false);

  const handleLinkClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (version == 1) {
    return (
      <div className="" style={{ backgroundColor: "#F3F3F3" }}>
        <div className="col-12 text-start fs-8 mt-3">
          If you are experiencing issues, please <a href="#" onClick={handleLinkClick}>write to us</a> or call us at 657-720-1296. Support Hours: Weekdays 8am - 5pm PST.
          
        </div>

        <div className="col-12 fs-8 mt-3 mb-4">
          Products underwritten by Veterinary Pet Insurance Company (CA),
          Columbus, OH; National Casualty Company (all other states),
          Columbus, OH. Agency of Record: DVM Insurance Agency. All are
          subsidiaries of Nationwide Mutual Insurance Company. Nationwide, the
          Nationwide N and Eagle, and Nationwide is on your side are service
          marks of Nationwide Mutual Insurance Company. ©2024 Nationwide.
          23RE-F9460A
        </div>
        <Support useSession={useSession} showModal={showModal} handleCloseModal={handleCloseModal} />
      </div>
      
    )
  }
  return (
    <footer className="justify-content-center bg-footer" >
      <div className="container" >
        <div className="row">

          <div className="col-12 text-left text-white py-2 px-3 mt-4 fs-8">
            If you are experiencing issues, please <a href="#" className="text-white" onClick={handleLinkClick}>write to us</a> or call us at 657-720-1296 . Support Hours: Weekdays 8am - 5pm PST.
          </div>

          <div className="col-12 text-left text-white py-2 px-3 fs-8 mt-2 mb-4">
            Products underwritten by Veterinary Pet Insurance Company (CA),
            Columbus, OH; National Casualty Company (all other states),
            Columbus, OH. Agency of Record: DVM Insurance Agency. All are
            subsidiaries of Nationwide Mutual Insurance Company. Nationwide, the
            Nationwide N and Eagle, and Nationwide is on your side are service
            marks of Nationwide Mutual Insurance Company. ©2024 Nationwide.
            23RE-F9460A
          </div>
        </div>
      </div>

      <Support useSession={useSession} showModal={showModal} handleCloseModal={handleCloseModal} />
    </footer>
  );
}

export default Footer;
