import { useState, useEffect } from "react";


// Set state of validation
export default function Graphic({ afi, handleAfiChange }) {

  const handleChange = (ev) => {
    // Check required with afi.is_required

    // Check format with afi.validation
    handleAfiChange(ev);
  }

  return (
    <input type="file" className="form-control" id={afi.var.name} name={afi.var.name} onChange={handleChange} />
  );
}
