import React, { useState } from 'react'
import { Modal, Card } from 'react-bootstrap';
import { FieldValidation } from "common/functions";
import { toast } from "react-toastify";
import MemberService from "api/agency/MemberService";
import swal from 'sweetalert';

const Support = ({ useSession, showModal, handleCloseModal }) => {

    const { getProfile } = useSession();
    const userDetail = getProfile();

    const [data, setdata] = useState({
      user_id: userDetail === null ? 0 : userDetail.user_id,
      method: "EMAIL",
      support_email: '',
      phone: '',
      full_name: '',
      support_category: '',
      description: '',
    });

    function handleChange(evt) {
        const value = evt.target.value;
        setdata({
            ...data,
            [evt.target.name]: value,
        });
    }

    const handleSupport = (e) => {
      // Validate filter
      let requiredFields = [
        "support_category",
        "description",
        "support_email",
        "phone",
        "full_name"
      ]
      let res = FieldValidation(requiredFields);
      if (res.result) {
        const req = {
          user_id: data.user_id,
          method: "EMAIL",
          email: data.support_email,
          phone: data.phone,
          full_name: data.full_name,
          support_category: data.support_category,
          description: data.description
        }

        MemberService.support(req)
          .then((response) => {
            swal("Support request has been submitted", "", "success");
            handleCloseModal();
          })
          .catch((error) => {
            swal("Error occurs while saving your request.", "error");
          });
      }
    };

  return (
    <div>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ "color": "#2950a3" }}>Customer Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <h6>If you are experiencing issues placing an order, or receiving an error, please fill out the form
                <br></br>
                below. This information is crucial for our support team to troubleshoot your issue.
              </h6>
            </Card.Header>
            <Card.Body>
              <div>
                <h6 className="fs-6 mt-2" style={{ "color": "#2950a3" }}>Your Name:*</h6>
                <input
                  id="full_name"
                  name="full_name"
                  className="form-control form-control-sm"
                  placeholder="Enter your name"
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <h6 className="fs-6 mt-2" style={{ "color": "#2950a3" }}>Email:*</h6>
                <input
                  id="support_email"
                  name="support_email"
                  className="form-control form-control-sm"
                  placeholder="Enter email address"
                  onChange={handleChange}
                  required
                />
              </div>

              <div>
                <h6 className="fs-6 mt-2" style={{ "color": "#2950a3" }}>Phone:*</h6>
                <input
                  id="phone"
                  name="phone"
                  className="form-control form-control-sm"
                  placeholder="Enter your phone number"
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mt-3">
                <h6 className="fs-6 mt-2" style={{ "color": "#2950a3" }}>Area you are having issue with:*</h6>
                <select id="support_category"
                  name="support_category"
                  className="form-control form-control-sm"
                  onChange={handleChange}
                  required
                >
                  <option value=""> </option>
                  <option value="Ordering a Product">Ordering a Product</option>
                  <option value="Shipping Issue">Shipping Issue</option>
                  <option value="My Account">My Account</option>
                  <option value="Shopping Cart">Shopping Cart</option>
                  <option value="Order History">Order History</option>
                </select>
              </div>

              <div className="mt-3">
                <h6 className="fs-6 my-2" style={{ "color": "#2950a3" }}>Please describe the issue you are experiencing:*</h6>
                <textarea
                  id="description"
                  name="description"
                  rows="5"
                  className="form-control form-control-sm"
                  placeholder="Enter description..."
                  onChange={handleChange}
                  required
                ></textarea>
              </div>

              <div className="row mt-3 justify-content-center" >
                <div className="col" style={{ "color": "darkred", "fontSize": "13px", lineHeight: "1.2rem" }}>
                  Phone: <a href="tel:657-720-1296" style={{ "color": "darkred" }}>657-720-1296</a><br />
                  Support Hours: Weekdays 8am - 5pm PST
                </div>

                <div className="col text-end">
                  <button className="btn btn-primary" onClick={handleSupport}>
                    Send
                  </button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>

    </div>    
  )

}
export default Support;