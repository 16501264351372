import { FieldValidation } from "common/functions";

/**
 * Global context for afis.
 * Manual conext requires state change and useMemo to triiger update
 * // Get new afi object when any value changed (used in customize setup)
 * const [afiValueChanged, setafiValueChanged] = useState(0);
 * setafiValueChanged(afiValueChanged + 1)
 * const curafi = useMemo(() => {
 *   return afiProvider.getafi(curafiName);
 * }, [curafiName, afiValueChanged]);
 */
class AFIProvider {
  // Active field interface 
  afis = null;
  // 12 cols grid
  afiLayout = null;
  // data/content template
  content = null;
  // Validations
  validations = null;

  constructor() {
    const storedAfis = sessionStorage.getItem('afis');
    this.afis = storedAfis && storedAfis !== "undefined" ? JSON.parse(storedAfis) : null;
  }

  /**
   * If session layout exists, return true
   * Otherwise loading a page layout from the server and store it in session for reuse, return false
   * 
   */
  load(customer_id, name) {
    // debugger;
    const storedLayout = sessionStorage.getItem("layout-" + name);
    if (storedLayout && storedLayout != "null" && storedLayout != "undefined") {
      
      const data = JSON.parse(storedLayout);
      this.saveAfis(data.afis);
      this.saveAfiLayout(data.afiLayout);
      return true;
    }
    else {
      AfiService.query(customer_id, name)
        .then((response) => {
          if (response.data.result == 1 && response.data.data) {
            sessionStorage.setItem("layout-" + name, JSON.stringify(response.data.data));
            this.saveAfis(response.data.data.afis);
            this.saveAfiLayout(response.data.data.page);
          }
        })
        .catch((error) => {
          console.error("Error fetching orders:", error);
        });
      return false;
    }
  }

  /**
   * 
   * Auto grid
   */
  pagination() {
    // Break down to chunks
    const afiKeys = Object.keys(this.afis);
    let pages = [];
    let rows = [];
    const colSize = grid[1];    // Chunk of cols [5, 1]
    for (let ii = 0; ii < afiKeys.length; ii += colSize) {
      // Collecting rows
      rows.push(afiKeys.slice(ii, ii + colSize));
      if (rows.length >= grid[0]) {
        // Move row to page
        pages.push(rows);
        rows = [];
      }
    }
    pages.push(rows);
    return pages;
  }

  /**
   * return list of afi [
   *  { key: afi_name, value: display_value }
   * ]
   */
  getAfiList() {
    // debugger;
    if (this.afis) {
      let rv = [];
      for (let key in this.afis) {
        rv.push({ key: key, value: key });
      }
      return rv;
    }
    return;
  }

  /**
   * Set this afis state
   * @param {*} newAfi
   */
  saveAfis (newAfis) {
    this.afis = newAfis;
    sessionStorage.setItem('afis', JSON.stringify(newAfis));
  }

  saveValidations(newValidations) {
    this.validations = newValidations;
  }

  /**
   * Set this afis state
   * @param {*} newAfi
   */
  saveAfiLayout (newAfiLayout) {
    this.afiLayout = newAfiLayout;
    sessionStorage.setItem('afiLayout', JSON.stringify(newAfiLayout));
  }

  getAfiLayout() {
    return this.afiLayout;
  }

  /**
   * Get AFI object
   * @param string name 
   * @returns {*}
   */
  getAfi(afiName) {
    return this.afis && this.afis[afiName] ? this.afis[afiName] : null;
  }

  /**
   * Get a spefic value from AFI object
   * @param string name - afi name
   * @param string field - afi field name
   * @param {*} value - actual value - 
   * If afi[field] is an object, concat parent and child name with "." dash
   * @returns 
   */
  getAfiValue(afiName, field) {
    if (this.afis && this.afis[afiName]) {
      if (typeof this.afis[afiName] === 'object' ) {
        const struct = field.split(".");
        return this.afis[afiName][struct[0]][struct[1]];
      }
      else {
        this.afis[afiName][field];
      }
    }
  }
  
  /**
   * Save this member content
   * @param {*} newContent 
   */
  saveAfiContent(newContent) {
    this.content = newContent;
    // sessionStorage.setItem('layout', JSON.stringify(newAfiLayout));
  }

  getAfiContent(afiName) {
    return this.content[afiName];
  }

  /**
   * return validation object
   * @param string name 
   * @return {
   *  "name": "Url",
   *  "regex": "",
   *  "pattern": "(https?:\\/\\/.*?\\.[a-z]{2,4}\\/[^\\s]*)/g",
   *  "auto_format": 0
   * }
   */
  getValidationPattern(afiName) {
    if (this.validations && this.validations[afiName]) {
       return this.validations[afiName].pattern;
    }
    return null
  }

  /**
   * Validate required
   * @param ["", ""] fields - default all afis
   * 
   */
  validateForm(fields) {
    // Check required with afi.display.is_required and afi.data.validation_id
    let vList = [];
    for (let key in this.afis) {
      if (!fields || fields.includes(key)) {
        const afi = this.afis[key];
        // Validate required
        if (afi.display.required) {
          vList.push(key);
        }
      }
    }

    if (vList.length > 0) {
      return FieldValidation(vList);
    }
    return true;
  }

  /**
   * returns all afi values for rendering or summary
   * @returns {
   *  "Photo": "dataUrl",
   *  "CallToAction": "",
   *  "CTAInteractive": "Visit precisionservicesgroup.com"
   * }
   */
  serialize() {
    if (this.afis) {
      let rv = {};
      for (var key in this.afis) {
        rv[key] = this.afis[key].user_value ? this.afis[key].user_value : "";
      }
      return rv;
    }
  }

  /**
   * Manually Update or add afi value
   * @param string name - afi name
   * @param string field - afi field name E.g: display-ui_type
   * @param {*} value - actual value - 
   * If afi[field] is an object, concat parent and child name with "-" dash
   * Example: listen-to => afi[listen][to] = value
   */
  updateAfiContent(name, newContent) {
    if (this.content) {
      this.content[name] = newContent;
    }
    console.debug(name + " AFI content updated");
  }

  /**
   * Update existing content for select component by pulling data from ajax
   * @param {*} - trigger - {
   *   myname: myname,
   *   source: listener.source,
   *   fill_with: listener.fill_with,
   *   owner_value: newvalue
   * }
   */
  ajaxSelectContent(trigger) {
    ListitemService.query(trigger.source, trigger.fill_with, trigger.owner_value)
      .then((response) => {
        if (response.data.result == 1) {
          this.updateAfiContent(trigger.myname, response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching listitem:", error);
      });
  }

  /**
   * Update or add afi value
   * @param string name - afi name
   * @param string field - afi field name
   * @param {*} value - actual value - 
   * If afi[field] is an object, concat parent and child name with "-" dash
   * Example: listen-to => afi[listen][to] = value
   */
  updateAfiValue(afiName, field, newvalue) {

    // debugger;
    if (this.afis && this.afis[afiName]) {
      // Create new
      if (!this.afis[afiName]) {
        this.afis[afiName] = {};
      }

      const struct = field.split(".");
      // Update second level field
      if (this.afis[afiName][struct[0]] && typeof this.afis[afiName][struct[0]] === 'object' ) {
        if (!this.afis[afiName][struct[0]]) {
          this.afis[afiName][struct[0]] = {};
        }
        this.afis[afiName][struct[0]][struct[1]] = newvalue;
      }

      // update main field
      else {
        this.afis[afiName][field] = newvalue;

        // update user_value
        if (field == "user_value") {
          // Loop through afi that listen to this field
          let triggers = [];
          for (var key in this.afis) {
            const listen = this.afis[key].listen;
            if (listen && listen.to == this.afis[afiName].name) {
              // evaluate condition
              let triggerUpdate = false;
              const testValue = this.afis[key].listen.test_value;
              switch (listen.compare) {
                case "true":    // Alway trigger
                  triggerUpdate = true;
                break;
                case "false":   // Skip trigger
                  triggerUpdate = false;
                break;
                case "eq":      // test_value in listen match target field new value
                  if (testValue == newvalue) {
                    triggerUpdate = true;
                  }
                  break;
                case "gt":
                  if (testValue > newvalue) {
                    triggerUpdate = true;
                  }
                  break;
                  case "gte":
                    if (testValue >= newvalue) {
                      triggerUpdate = true;
                    }
                  break;
                  case "lt":
                    if (testValue < newvalue) {
                      triggerUpdate = true;
                    }
                  break;
                  case "lte":
                    if (testValue <= newvalue) {
                      triggerUpdate = true;
                    }
                  break;
                  case "like":
                    if (testValue.toLowerCase() == newvalue.toLowerCase()) {
                      triggerUpdate = true;
                    }
                  break;
                  case "in":
                    const parts = testValue.split();
                    if (parts.indexOf(newvalue) > 0) {
                      triggerUpdate = true;
                    }
                  break;
              }

              // Trigger update
              if (triggerUpdate) {
                // Update list take president if exist
                if (listen.source) {
                  this.afis[key].listen.owner_value = newvalue;
                }
                else if (listen.update_text) {
                  this.afis[key].user_value = listen.update_text;
                }
                
                const trigger = {
                  myname: key,
                  source: listen.source,
                  fill_with: listen.fill_with,
                  owner_value: newvalue
                };
                switch (this.afis[key].display.ui_type) {
                  case "select":
                    this.ajaxSelectContent(trigger);
                    break;
                  case "table":
                    break;
                  case "img":
                    break;
                }
                triggers.push(trigger);
              }
            }
          }
          return triggers;
        }
      }
    }
  }; 

  emptyAfis() {
    afis = null;
    sessionStorage.removeItem('afis');
  };

}

export default new AFIProvider();