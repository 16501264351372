import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { useShoppingCart } from "context/ShoppingCartContext";
import Address from 'control/afi/element/Address';
import appConstant from "constant/index";
import MemberService from "api/agency/MemberService";
import HtmlPreview from "control/afi/template/HtmlPreview"
import { toast } from "react-toastify";


function Redeem({ user, totalPoint }) {
  const { cartItems, emptyCart, updateCartItem } = useShoppingCart(null);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [address, setAddress] = useState({
    line1: user.shipping_address ? user.shipping_address.line1 : "",
    city: user.shipping_address ? user.shipping_address.city : "",
    state: user.shipping_address ? user.shipping_address.state : "",
    zip_code: user.shipping_address ? user.shipping_address.zip_code : "",
    country: "US"
  });

  const [orderPlaced, setOrderPlaced] = useState(false);

  useEffect(() => {
    document.title = 'Redeem';
    window.scrollTo(0, 0);
  }, []);

  const handleImageError = (e) => {
    e.stopPropagation();
    e.preventDefault();
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/noimages.png';
  };

  // Validate dog tags
  const validateOrder = () => {
    let ok = true;

    cartItems.map((item) => {
      if (item.id == 148680) {
        let el = document.getElementById("pet_tag_name" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_name == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        /*
        el = document.getElementById("pet_tag_line1" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_line1 == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        el = document.getElementById("pet_tag_line2" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_line2 == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        */
      }
    })

    // Validate address
    const el = document.getElementById("new-address");
    if (el.checked) {
      if (!address.line1 || !address.city || !address.state || !address.zip_code) {
        msg.style.display = "block";
        ok = false;
      }
    }

    let msg = document.getElementById("pet_tag_info");
    if (msg) {
      if (ok) {
        msg.style.display = "none";
      }
      else {
        msg.style.display = "block";
      }
    }
  
    return ok;
  }

  const orderNow = () => {
    // debugger;
    let shippingAddress = null;
    const el = document.getElementById("new-address");
    if (el.checked) {
      shippingAddress = address;
    }

    const article = {
      user_id: user.user_id,
      customer_id: appConstant.CUSTOMER_ID,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      delivery_method_id: appConstant.DELIVERY_METHOD_ID,
      payment_method_id: appConstant.PAYMENT_METHOD_ID,
      shipping_address_id: user.shipping_address.id,
      shipping_address: shippingAddress,
      items: cartItems
    }

    // Avoid duplicate order because email service is slow
    MemberService.placeOrder(article)
      .then((res) => {
        if (res.data.result == 1) {
          emptyCart();
          setOrderPlaced(true);
          // Update profile
          MemberService.account(user.user_id)
            .then((res) => {
              if (res.data.result == 1) {
                // Update journeys
                const storedCartItems = sessionStorage.getItem("profile");
                let profile = storedCartItems ? JSON.parse(storedCartItems) : [];
                profile.journeys = res.data.data.journeys;
                sessionStorage.setItem("profile", JSON.stringify(profile));
              }
            });
        }
        setOrderPlaced(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error("Error fetching report data:", error);
      });
  }

  const handlePlaceOrder = (e) => {
    e.stopPropagation();
    e.preventDefault();

    let ok = true;
    cartItems.map((item) => {
      if (item.id == 148680) {
        let el = document.getElementById("pet_tag_name" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_name == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        /*
        el = document.getElementById("pet_tag_line1" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_line1 == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        el = document.getElementById("pet_tag_line2" + 148680);
        el.classList.remove("is-invalid");
        if (item.pet_tag_line2 == "") {
          el.classList.add("is-invalid");
          ok = false;
        }
        */
      }
    })

    // Validate address
    const el = document.getElementById("new-address");
    if (el.checked) {
      if (!address.line1 || !address.city || !address.state || !address.zip_code) {
        msg.style.display = "block";
        ok = false;
      }
    }

    let msg = document.getElementById("pet_tag_info");
    if (msg) {
      if (ok) {
        msg.style.display = "none";
      }
      else {
        msg.style.display = "block";
      }
    }

    if (ok) {
      MemberService.validateAddress(address)
        .then((res) => {
          if (res.data.result == 1) {
            orderNow();
            window.scroll(0, 200);
          }
          else {
            let el = document.getElementById("address_message");
            el.classList.remove("d-none")
            return false;
          }
        })
        .catch((error) => {
          console.error("Error fetching report data:", error);
        });
    }
  }

  const handleAddressChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const value = e.target.value;
    const name = e.target.name;
    // debugger;
    setAddress({ ...address, [name]: value });
  }

  const handlePetTag = (ev, productId) => {
    let data = {};
    if (ev.target.name == "pet_tag_name" + productId) {
      updateCartItem(productId, {pet_tag_name: ev.target.value });
    }
    if (ev.target.name == "pet_tag_line1" + productId) {
      updateCartItem(productId, { pet_tag_line1: ev.target.value });
    }
    if (ev.target.name == "pet_tag_line2" + productId) {
      updateCartItem(productId, { pet_tag_line2: ev.target.value });
    }    
  }

  const saveAddress = (data) => {
    MemberService.saveAddress(data)
      .then((res) => {
        if (res.data.result == 1) {
        }
      })
      .catch((error) => {
        console.error("Error saving address:", error);
      });
  }

  return (orderPlaced) ? (
    <>
      <h1 className="fm-tiempos fw-bolder fs-1 text-center my-5">Thank you!</h1>
      <div className="mb-2 p-3">
        Please allow up to 7 business days to process and ship your order.
        We'll send you another email with tracking information when your prize
        has shipped.
      </div>

    </>
  ) : (
    <>
      <h1 className="fm-tiempos fw-bolder fs-1 text-center my-5">Order Preview</h1>
      <div className="card card-body">
        <div className="">
          {
            cartItems && cartItems.map((item, idx) => (
                item.id == 148680 ?
                <div className="row" key={item.id}>
                  <div className="col-lg-6 col-md-6 col-sm-12 text-center">
                    <div className="text-center mb-2">
                      <div class="btn-group">
                        <button type="button" class="btn btn-outline-primary" onClick={() => setPage(1)}>
                          <span className="fs-7">Front</span>
                        </button>
                        <button type="button" class="btn btn-outline-primary" onClick={() => setPage(2)}>
                          <span className="fs-7">Back</span>
                        </button>
                      </div>
                    </div>

                    <HtmlPreview product={item} page={page}  />
                  </div>
                  <div className="col fs-2 ">

                    <div className="row">
                      <div className="col-12">
                        <div className="fs-6 fw-bold">Engraving information</div>
                        <div className="fs-6 fw-bold text-danger">*Maximum characters per line = 12</div>
                        <div className="row mt-2">
                          <div className="col-sm-5">
                            <label className="fs-7">Pet name*</label>
                          </div>
                          <div className="col-sm-7">
                            <input type="text" maxlength="12" className="form-control form-control-sm" placeholder="Pet name" id={"pet_tag_name" + item.id} name={"pet_tag_name" + item.id} value={item.pet_tag_name} onChange={(e) => handlePetTag(e, item.id)} required />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <label className="fs-7">Line1</label>
                          </div>
                          <div className="col-sm-7">
                            <input type="text" maxlength="12"  className="form-control form-control-sm mt-1" placeholder="Line 1" id={"pet_tag_line1" + item.id} name={"pet_tag_line1" + item.id} value={item.pet_tag_line1} onChange={(e) => handlePetTag(e, item.id)} />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5">
                            <label className="fs-7">Line2</label>
                          </div>
                          <div className="col-sm-7">
                            <input type="text" maxlength="12" className="form-control form-control-sm mt-1" placeholder="Line 2" id={"pet_tag_line2" + item.id} name={"pet_tag_line2" + item.id} value={item.pet_tag_line2} onChange={(e) => handlePetTag(e, item.id)} />
                          </div>
                        </div>
                        <div id="pet_tag_info" className="text-danger fs-7 fw-bold" style={{display: "none"}}>* Pet name is required</div>
                      </div>
                    </div>
                  </div>
                  {idx < cartItems.length - 1 && <hr />}
                </div>
                :
                <div className="row" key={item.id}>
                  <div className="col-lg-6 col-sm-12 text-center p-1">
                    <img src={appConstant.DOCUMENT_ROOT + "/dist/img/product/" + item.id + ".jpg"} className="img-fluid w-50" onError={handleImageError} />
                  </div>
                  <div className="col fs-2 my-auto">
                      {item.name}
                  </div>
                  {idx < cartItems.length - 1 && <hr />}
                </div>
            ))
          }
        </div>
      </div>

      {
        user.shipping_address &&
        <div className="row">
          <div className="col-12 text-left fs-2 mt-4 mb-4">
            <p className="lh-sm">
              {user.shipping_address.line1}
              <br />
              {user.shipping_address.city + ", " + user.shipping_address.state + " " + user.shipping_address.zip_code}
            </p>
          </div>
        </div>
      }

      <div className="row mt-3">
        <div className="col-2 text-left">
          <input id="new-address" type="checkbox" className="form-control" data-bs-target="#collapseAddr" data-bs-toggle="collapse" aria-expanded="false" />
        </div>
        <div className="col-10 text-left">
          <p className="fs-2 m-0">Ship to different address.</p>
          <p >Note: Shipping to a different address will not update the address associated with your policy</p>
        </div>
      </div>

      <div className="collapse" id="collapseAddr">
        <div className="card card-body w-100">
          <Address address={address} handleAddressChange={handleAddressChange} />
        </div>
      </div>
      <div id="address_message" className="text-danger d-none mt-2">Address is not deliverable.</div>

      <div className="row">
        <div className="mx-4 border-bottom mt-3" style={{ "height": "2px", "backgroundColor": "#888" }} />
        <div className="col-12 text-center fs-4 my-4">
          Orders arrive in 7-10 business days
        </div>
        <div className="col-12 text-center mt-2 mb-2">
          <button type="button" className="btn btn-primary btn-lg" onClick={handlePlaceOrder} >Place order</button>
        </div>
      </div>
    </>
  );
}
export default Redeem;
