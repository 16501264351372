import { useState, useEffect, useMemo, useCallback } from "react";
import { FieldValidation } from "common/functions";

export default function Text({ name, afiProvider }) {
  const afi = afiProvider.getAfi(name);

  const [userVal, setUserVal] = useState(afi.user_value ? afi.user_value : "");

  const handleChange = (ev) => {
    setUserVal(ev.target.value);

    // Check format with afi.validation
    // afiProvider.getValidation(afi.data.validation_id);

    const triggers = afiProvider.updateAfiValue(ev.target.name, "user_value", ev.target.value);
    if (triggers.length > 0) {
      setformChanged(afi.name);
    }
  }

  return afi && (
    <input type="text" name={afi.name}
      id={afi.name}
      className={afi.display.class ? afi.display.class : "form-control"}
      placeholder={afi.display.placeholder}
      value={userVal}
      onChange={handleChange}
      readOnly={afi.display.readonly}
      required={afi.display.required}
      disabled={afi.display.disabled}
    />
  );
}
