import React, { useContext, useState, Suspense } from "react";
import LoadingBar from "react-top-loading-bar";
import { Routes, Route, Outlet } from "react-router-dom";
import ScrollToTop from "./navigation/ScrollToTop";
import appConstant from "constant/index";

import Header from "./navigation/Header";
import Footer from "./navigation/Footer";
import Share from "./home/index";
import Dashboard from "./home/Dashboard";
import Rewards from "./rewards/index";

import Account from "./account/index";
import Categories from "./product/Categories";
import ProuctDetail from "./product/Detail";
import CustomizeForm from "./product/CustomizeForm";
import CustomizeFabric from "./product/CustomizeFabric";
import CustomizeSetup from "./product/CustomizeSetup";


export default function MainLayout(props) {
  // debugger;
  
  const allroutes = [
    { url: appConstant.DOCUMENT_ROOT + "/", component: <Share useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/home", component: <Share useSession={props.useSession} /> },

    { url: appConstant.DOCUMENT_ROOT + "/history", component: <Rewards useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/redeem", component: <Rewards useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/checkout", component: <Rewards useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/dashboard", component: <Dashboard useSession={props.useSession} /> },

    { url: appConstant.DOCUMENT_ROOT + "/product/categories:categoryId?", component: <Categories useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/product/detail:productId", component: <ProuctDetail useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/product/customize-form/:productId", component: <CustomizeForm useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/product/customize-interactive:productId", component: <CustomizeFabric useSession={props.useSession} /> },
    { url: appConstant.DOCUMENT_ROOT + "/product/customize-setup/:productId?", component: <CustomizeSetup useSession={props.useSession} /> },

    // { url: appConstant.DOCUMENT_ROOT + "/cart/preview", component: <Preview /> },
    // { url: appConstant.DOCUMENT_ROOT + "/cart/shipping", component: <Shipping /> },
    // { url: appConstant.DOCUMENT_ROOT + "/order/:orderId?/:orderType?", component: <Order /> },
  ];

  return (
    <>
      <LoadingBar height={3} color="#28a745" progress={100} />
      <Header useSession={props.useSession} />
      <Routes>
          {allroutes.map((data, i) => (
            <Route
              key={`${data.url}`}
              exact
              path={data.url}
              element={data.component}
            />
          ))}
      </Routes>
      <Footer useSession={props.useSession} varsion={2} />

    </>
  );
};

