import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCheckbox from 'control/afi/element/ProductCheckbox';
import MemberService from 'api/agency/MemberService';
import appConstant from 'constant/index';
import { useShoppingCart } from "context/ShoppingCartContext";
import { groupBy } from "common/functions";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import { toast } from "react-toastify";




// https://swiperjs.com/swiper-api

function Product({ user, totalPoint }) {
  const navigate = useNavigate();
  const [remainPoint, setRemain] = useState(totalPoint.total_point);
  
  const [products, setProducts] = useState(null);
  const {cartItems, itemInCart, addToCart, removeFromCart, emptyCart } = useShoppingCart();
  const [listproduct, setListProduct] = useState("swipe");

  useEffect(() => {
    document.title = 'Redeem - Product';
    // Get products
    // debugger;
    MemberService.listProducts({
      customer_id: appConstant.CUSTOMER_ID,
      product_Category_id: appConstant.PRODUCT_CATEGORY_ID,
      exclude_no_product: 1,
      is_Active: 1
    })
      .then((res) => {
        if (res.data.result == 1) {
          setProducts(res.data.data);
        }
      });
  }, []);

  const addItem = (product) => {
    // debugger;
    let point = product.points;
    if (remainPoint >= point) {
      addToCart(product, 1);
      setRemain(remainPoint - point);
    }
  }

  const removeItem = (product) => {
    // debugger;
    let point = product.points;
    removeFromCart(product.id);
    setRemain(remainPoint + point);
  }

  const toggleListProduct = (e) => {
    e.stopPropagation();
    setListProduct(() => listproduct == "swipe" ? "all" : "swipe");
  }

  const allProducts = useMemo(() => {
    const grouped = groupBy(products, "points");
    let rv = [];
    let category = null;
    // debugger;
    for (let key in grouped) {
      category = key;
      rv.push(
        <>
          <h3 className="fm-sans fw-bold my-4">{category} Secret Agent Bucks</h3>
          <div className="row justify-content-center">
            {
              grouped[key].map((product, idx) => (
                <div className="col-sm-10 col-md-10 col-lg-6  text-center" key={idx}>
                  <ProductCheckbox product={product}
                    itemInCart={itemInCart} addItem={addItem}
                    removeItem={removeItem} remainPoint={remainPoint} />
                </div>
              ))
            }
          </div>
        </>);
    }
    return rv;
  }, [products, remainPoint]);

  const groupedProducts = useMemo(() => {
    const grouped = groupBy(products, "points");

    let rv = [];
    let category = null;
    for (let key in grouped) {
      category = key;
      rv.push(
        <>
          <h3 className="fm-sans fw-bold my-4">{category} Secret Agent Bucks</h3>
          <div className="row justify-content-center">
            <div className="col-12">
              <Swiper key={key} navigation={true} modules={[Navigation]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={0}
                breakpoints={{
                  1024: {
                    slidesPerView: 2
                  },
                }}
              >
                {
                  grouped[key].map((product, idx) => (
                    <SwiperSlide key={idx}>
                      <ProductCheckbox product={product} key={"p-" + idx}
                        itemInCart={itemInCart} addItem={addItem}
                        removeItem={removeItem} remainPoint={remainPoint} />
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>
          </div>
        </>);
    }
    return rv;
  }, [products, remainPoint]);

  const pointsIncart = useMemo(() => {
    let points = 0;
    cartItems.map((item) => points = points + item.points);
    setRemain(totalPoint.total_point - points);
    return points;
  }, [remainPoint]);

  const handleImageError = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };

  const resetSelection = (ev) => {
    ev.stopPropagation();
    emptyCart();
    document.location = "/agencyjourney/redeem";
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-5 text-end">
          <img src={appConstant.DOCUMENT_ROOT + "/dist/img/bootbuck.jpg"} style={{ "width": "70%", "maxWidth": "140px" }} />
        </div>
        <div className="col-7 text-center my-auto">
          <div className="fw-bold fs-5 fst-italic text-capitalize" style={{ color: "#0047BB" }}>{user.first_name} {user.last_name}</div>
          <div className="fm-sans mt-1 fst-italic fs-2"><strong>{totalPoint.total_point}</strong> Secret Agent Bucks</div>
        </div>
      </div>

      <h1 className="fm-tiempos fw-bolder text-center mt-5">Rewards</h1>
      <div className="row">
        <div className="col-12 text-center fs-4">
          <a href="#" onClick={toggleListProduct} >{listproduct == "swipe" ? "See all" : "See row"}</a>
        </div>
      </div>
      {listproduct == "swipe" ? groupedProducts : allProducts}

      <div className="row  mt-4">
        <div className="col-12 text-center">
          <button
            type="button" className="btn btn-primary" onClick={() => navigate("/agencyjourney/checkout")}
            disabled={cartItems.length < 1}
          >
            Redeem {pointsIncart}
          </button>
        </div>

        {
          cartItems.length >= 1 && 
          <div className="col-12 text-center mt-2">
              <button type="button" className="btn btn-secondary" onClick={resetSelection} >
              Reset selections
            </button>
          </div>
        }

        <div className="col-12 text-center mt-2">
          <a href="#" onClick={() => navigate("/agencyjourney")} className="fs-2">Back to Home</a>
        </div>
      </div>
    </>
  );

}

export default Product;
