import http from "./Endpoint";
import appConstant from "constant";

/**
 * 
 */
class ProductService {
  projection = {
    "order_by": {
      "name": "ASC"
    },
    "limit": 100,
    "offset": 0
  }

  /**
   * Query product information
   * @param int productId - Produc Id
   * @param [] includes - specify additinal data return
   * ["pick_fees", "quantity_list", "department_list", "inventories_logs", "actual_inventory", "price_ranges", "afis"]
   * @returns {*}
   */
  query(productId, includes) {
    const article = {
      "filter": {
        "id": productId
      },
      "return": includes
    }
    return http().post("/product/query", article);
  }

  /**
   * Query AFI info and prefill list items with user data
   * @param int productId 
   * @param int userId
   * @param int restart = default 0
   * @returns - [ ..., afis:{*}, afi_layout:{*} ]
   */
  queryAfis(productId, userId, restart) {
    const article = {
      "filter": {
        "product_id": productId,
        "user_id": userId,
        "restart": restart
      }
    }
    return http().post("/product/queryAfis", article);
  } 


  /**
   * Get list of product categories for a customer
   * @param int customerId
   * @param [] includes  - specify additinal data return
   * @returns 
   */
  category(customerId, includes) {
    const article = {
      "filter": {
        "customer_id": customerId,
        "exclude_no_product" : 0
      },
      "return": includes
    }
    return http().post("/product/categories", article);
  }

  /**
   * Move destroy product to cart
   * @param int productId, userId, customerId
   * @param string option - "ADD2CART"
   * @returns 
   */
  destroy(productId, userId, customerId, option) {
    const article = {
      id: productId,
      user_id: userId,
      customer_id: customerId,
      option: option
    }
    return http().post("/product/destroy", article);
  }

  /**
   * Get a list of products
   * @param int customerId 
   * @param {*} ands - additional filter
   * @param [] includes -  specify additinal data return
   * @returns 
   */
  list(customerId, ands, includes) {
    let filter = { "customer_id": customerId }
    if (ands) {
      filter = { ...filter, ...ands };
    }
    const article = {
      "filter": filter,
      "projection": this.projection,
      "return": includes
    }
    return http().post("/product/list", article);
  }

  /**
   * Deactivate a product
   * @param int  productId, customerId
   * @returns 
   */
  delete(productId, customerId) {
    const article = {
      id: productId,
      customer_id: customerId
    };
    return http().post("/product/delete", article);
  }

  /**
   * Update product Info
   * @param int productId 
   * @param {*} data - field and value pare to be update 
   * @returns 
   */
  update(productId, data) {
    return http().post("/product/update", data);
  }

  /**
   * Update Afis for customizable product
   * @param {*} prodcustId 
   * @param {*} customerId 
   * @param {*} afis 
   */
  updateAfis(productId, customerId, afis) {
    const article = {
      product_id: productId,
      customer_id: customerId,
      afis: afis
    };
    return http().post("/product/updateAfis", article);
  }

  /**
   * Get list of product types
   * @returns 
   */
  types() {
    let article = {
      "category": "quickbook_types"
    }
    return http().post("/product/enum", article);
  }

}

export default new ProductService();