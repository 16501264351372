/**
 * Global context for shopping cart
 * To access global context: 
 * const { addToCart, incrementQuantity, decrementQuantity, totalItem, totalQty, cartItems, calculateGrandTotal, removeFromCart, emptyCart } = useShoppingCart();
 */
import React, { createContext, useState, useContext, useEffect } from 'react';

const ShoppingCartContext = createContext();

export function ShoppingCartProvider({ children }) {

  const [cartItems, setCartItems] = useState(() => {
    const storedCartItems = sessionStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) : [];
  });

  useEffect(() => {
    sessionStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCart = (item, qty) => {
    //debugger;
    const existingItem = cartItems.find((cartItem) => cartItem.id === item.id);
    if (existingItem) {
      setCartItems((prevCartItems) =>
        prevCartItems.map((cartItem) =>
          cartItem.id === item.id ? { ...cartItem, quantity: parseInt(qty) } : cartItem
        )
      );
    } else {
      setCartItems((prevCartItems) => [
        ...prevCartItems,
        { ...item, quantity: parseInt(qty) },
      ]);
    }
  };

  // data = { pet_tag_name: "", pet_tag_line1: "", pet_tag_line2: ""}
  const updateCartItem = (itemId, data) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === itemId ? { ...item, ...data } : item
      )
    );
  };

  const itemInCart = (productId) => {
    return cartItems.find((cartItem) => cartItem.id === productId);
  }

  const removeFromCart = (itemId) => {
    const itemIndex = cartItems.findIndex((item) => item.id === itemId);

    if (itemIndex !== -1) {
      const newCartItems = [...cartItems];
      newCartItems.splice(itemIndex, 1);
      setCartItems(newCartItems);
    }
  };

  const incrementQuantity = (itemId) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === itemId ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const calculateGrandTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const decrementQuantity = (itemId) => {
    setCartItems((prevCartItems) =>
      prevCartItems.map((item) =>
        item.id === itemId ? { ...item, quantity: Math.max(0, item.quantity - 1) } : item
      )
    );
  };

  const totalQty = () => {
    return cartItems.reduce((total, item) => total + item.quantity, 0);
  };

  const totalItem = (itemId) => {
    const item = cartItems.find((item) => item.id === itemId);
    return item ? item.quantity : 0;
  };

  const emptyCart = () => {
    sessionStorage.setItem('cartItems', "[]");
    setCartItems([]);
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItems,
        addToCart,
        updateCartItem,
        itemInCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        totalQty,
        totalItem,
        calculateGrandTotal,
        emptyCart
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
}

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}
