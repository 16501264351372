import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Link, useParams } from 'react-router-dom';
import XmpieService from "api/precision/PreorderService";
// import { List, ListItem, ListItemText, ListItemButton } from '@mui/material';

/**
 * Edit afi form
 * @param {*} 
 */
export default function AfiSetup({ curAfiName, afiProvider }) {

  const afiNameList = afiProvider.getAfiList();

  const [display, setDisplay] = useState();
  const [data, setData] = useState();
  const [listen, setListen] = useState();
  const [canvas, setCanvas] = useState()
  const [font, setFont] = useState();

  useEffect(() => {
    const afi = afiProvider.getAfi(curAfiName);
    if (afi) {
      setDisplay(afi.display);
      setData(afi.data);
      setListen(afi.listen);
      setCanvas(afi.canvas);
      setFont(afi.font);
    }
  }, [curAfiName]);

  const handleChange = (ev) => {
    let newVal = ev.target.value;
    if (ev.target.type == "checkbox" || ev.target.type == "radio") {
      newVal = ev.target.checked ? 1 : 0;
    }

    const parts = ev.target.name.split(".");
    switch (parts[0]) {
      case "display":
        setDisplay({ ...display, [parts[1]]: newVal });
        break;
      case "data":
        setData({ ...data, [parts[1]]: newVal });
        break;
      case "listen":
        setListen({ ...listen, [parts[1]]: newVal });
        break;
      case "canvas":
        setCanvas({ ...canvas, [parts[1]]: newVal });
        break;
      case "font":
        setFont({ ...font, [parts[1]]: newVal });
        break;
    }
    const triggers = afiProvider.updateAfiValue(curAfiName, ev.target.name, newVal);
  }

  const fontFamilyList = [];
  const custVars = [];

  if (display) {
    return (
      <div className="mt-4">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-general-tab" data-bs-toggle="tab"
              data-bs-target="#nav-general" type="button" role="tab" aria-controls="nav-general" aria-selected="true">Display</button>
            <button className="nav-link" id="nav-option-tab" data-bs-toggle="tab" data-bs-target="#nav-option"
              type="button" role="tab" aria-controls="nav-option" aria-selected="false">Pre-fill</button>
            <button className="nav-link" id="nav-trigger-tab" data-bs-toggle="tab" data-bs-target="#nav-trigger"
              type="button" role="tab" aria-controls="nav-trigger" aria-selected="false">Trigger</button>
            <button className="nav-link" id="nav-interactive-tab" data-bs-toggle="tab" data-bs-target="#nav-interactive"
              type="button" role="tab" aria-controls="nav-interactive" aria-selected="false">Interactive</button>
          </div>
        </nav>

        <div className="tab-content mt-3" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-general" role="tabpanel" aria-labelledby="nav-general-tab" tabIndex="0">
            <div className="form-group">
              <div className="input-group mb-2">
                <label htmlFor="display.input_label" className="input-group-text w-25">Label</label>
                <input value={display.input_label}
                  id="display.input_label"
                  name="display.input_label"
                  className="form-control"
                  type="text"
                  onChange={handleChange} />
              </div>

              <div className="input-group mb-2">
                <label className="input-group-text w-25" htmlFor="display.ui_type">Type</label>
                <select value={display.ui_type} id="display.ui_type" name="display.ui_type" className="form-control" onChange={handleChange}>
                  <option value=""></option>
                  <optgroup label="Input">
                    <option value="text">Text Box</option>
                    <option value="list">Dropdown List</option>
                    <option value="number">Number</option>
                    <option value="tel">Phone</option>
                    <option value="email">Email</option>
                    <option value="url">Url</option>
                    <option value="date">Date</option>
                    <option value="textarea">Text area</option>
                    <option value="graphic">Graphic</option>
                    <option value="excel">Excel (Import)</option>
                  </optgroup>
                  <optgroup label="Display" >
                    <option value="table">Table</option>
                    <option value="link">Link</option>
                    <option value="button">Button</option>
                  </optgroup>
                </select>
              </div>

              <div className="input-group mb-2">
                <div className="col form-check">
                  <input id="display.required" name="display.required" checked={display.required}
                    className="form-check-input" type="checkbox" onChange={handleChange}  />
                  <label className="form-check-label" htmlFor="display.required">Required</label>
                </div>

                <div className="col form-check">
                  <input name="display.hidden" id="display.hidden" className="form-check-input"
                    type="checkbox" onChange={handleChange} checked={display.hidden} />
                  <label className="form-check-label ms-2" htmlFor="display.hidden">Hidden</label>
                </div>

                <div className="col form-check">
                  <input id="display.read_only" name="display.read_only" className="form-check-input"
                    type="checkbox" onChange={handleChange} checked={display.read_only } />
                  <label className="form-check-label ms-2" htmlFor="display.read_only">Ready Only</label>
                </div>
              </div>
              
              <div className="input-group mb-2">
                <label className="input-group-text w-25" htmlFor="display.transform">Transform Value</label>
                <select value={display.transform || ""} id="display.transform" name="display.transform" className="form-control" onChange={handleChange}>
                  <option value=""> </option>
                  <option value="capitalize">Capitalize</option>
                  <option value="uppercase">Upper case</option>
                  <option value="lowercase">Lower case</option>
                  <option value="money">Money</option>
                </select>
                <div className="valid-feedback d-block" >* Transform user value before and after display</div>
              </div>

              <div className="input-group mb-2">
                <label className="input-group-text w-25" htmlFor="data.formula">Formula</label>
                <input type="text" value={data.formula || ""} id="data.formula" name="data.formula"
                  className="form-control"
                  placeholder="use {{}} for field substitution" onChange={handleChange} />
                <div className="valid-feedback d-block" >* Apply user value to a template</div>
              </div>

              <div className="input-group mb-2">
                <label className="input-group-text w-25" htmlFor="display.default">Default</label>
                <input type="text" value={display.default || ""} id="display.default" placeholder="Use when empty"
                  name="display.default" className="form-control" onChange={handleChange} />
              </div>

              <div className="input-group mb-2">
                <label className="input-group-text w-25" htmlFor="display.hint">Hint</label>
                <input type="text" value={display.hint || ""} id="display.hint" name="display.hint" className="form-control"
                  placeholder="" onChange={handleChange} />
              </div>

            </div>
          </div>

          <div className="tab-pane fade" id="nav-option" role="tabpanel" aria-labelledby="nav-option-tab" tabIndex="0">
            <div className="input-group mb-2">
              <label className="input-group-text w-25" htmlFor="data.validation_id">Validation</label>
              <select value={data.validation_id || ""} id="data.validation_id"
                name="data.validation_id" className="form-control" onChange={handleChange}>
                <option value=""> </option>
                {

                  afiProvider.validations && Object.keys(afiProvider.validations)
                    .map((key, idx) => {
                      const val = afiProvider.validations[key];
                      return (
                        display.ui_type == "tel" && (key > 1000 && key < 2000) && <option key={idx} value={key}>{val.name}</option>
                        || display.ui_type == "number" && (key > 2000 && key < 3000) && <option key={idx} value={key}>{val.name}</option>
                        || display.ui_type == "email" && (key == 3005) && <option key={idx} value={key}>{val.name}</option>
                        || display.ui_type == "url" && (key == 3004) && <option key={idx} value={key}>{val.name}</option>
                        || display.ui_type == "text" && (key > 3000 && key < 4000) && <option key={idx} value={key}>{val.name}</option>
                        || display.ui_type == "data" && (key > 4000 && key < 5000) && <option key={idx} value={key}>{val.name}</option>
                      )
                    })
                }
              </select>
            </div>

            <div className="input-group mb-2">
              <label className="input-group-text w-25">Fill Source</label>
              <select value={data.source || ""} id="data.source" name="data.source" className="form-control" onChange={handleChange}>
                <option value=""> </option>
                {
                  display.ui_type == "list" && (
                    <>
                      <option key={1} value="custom_list">Custom List</option>
                      <option key={2} value="user_address_list">User Address List</option>
                      <option key={3} value="department_address_list">Department Address List</option>
                      <option key={4} value="image_list">Image List</option>
                    </>
                  ) ||
                  display.ui_type == "table" && (
                    <>
                      <option key={1} value="user_address_table">User Address Table</option>
                      <option key={2} value="department_address_table">Department Address Table</option>
                      <option key={3} value="inventory_table">Inventory Table</option>
                    </>
                  ) ||
                  display.ui_type == "graphic" && (
                    <>
                      <option key={1} value="image_upload">Image Upload</option>
                      <option key={3} value="gallery">Gallery</option>
                    </>
                  ) ||
                  (
                    <option key={1} value="user_profile">User Profile</option>
                  )
                }
              </select>
            </div>

            <div className="input-group mb-2">
              <label className="input-group-text w-25">Fill With</label>
              {
                (data.source == "custom_list" || data.source == 'image_list') && (
                  <div className="mb-2 ms-2">
                    {"Build property list"}
                  </div>
                ) ||
                (data.source == 'user_profile') && (
                  <select value={data.fill_with || ""} id="data.fill_with" name="data.fill_with" className="form-control" onChange={handleChange}>
                    <option value=""> </option>
                    <optgroup label="User Profile">
                      <option value="title">Title</option>
                      <option value="full_name">Full Name</option>
                      <option value="first_name">First Name</option>
                      <option value="last_name">Last Name</option>
                      <option value="email">Email</option>
                      <option value="phone">Phone</option>
                      <option value="cell">Cell</option>
                      <option value="fax">Fax</option>
                      <option value="user_variable">User Variable</option>
                    </optgroup>
                  </select>
                ) ||
                (data.source == 'user_address_list' || data.source == 'department_address_list') && (
                  <select value={data.fill_with || ""} id="data.fill_with" name="data.fill_with" className="form-control" onChange={handleChange}>
                    <option value=""> </option>
                    <optgroup label="Address">
                      <option value="title">Title</option>
                      <option value="line1">Line 1</option>
                      <option value="line2">Line 2</option>
                      <option value="city">City</option>
                      <option value="state">State</option>
                      <option value="province">Province</option>
                      <option value="zip_code">Zip Code</option>
                      <option value="country">Country</option>
                      <option value="phone">Phone</option>
                      <option value="citystate">City State</option>
                      <option value="citystatezip">City, State Zipcode</option>
                    </optgroup>
                  </select>
                )
              }
            </div>
            
            {
              data.fill_with == "user_variable" && (
                <div className="input-group mb-2">
                  <label className="input-group-text w-25" htmlFor="data.uvar_name">Variable Name</label>
                  <input type="text" value={data.uvar_name || ""} id="data.uvar_name" name="data.uvar_name" className="form-control"
                    placeholder="" onChange={handleChange} />
                </div>
              )
            }

          </div>

          <div className="tab-pane fade" id="nav-trigger" role="tabpanel" aria-labelledby="nav-trigger-tab" tabIndex="0">
            <div className="input-group mb-2">
              <label htmlFor="listen.to" className="input-group-text w-25">Listen to</label>
              <select value={listen.to || ""} name="listen.to" id="listen.to" className="form-control" onChange={handleChange}>
                <option value=""></option>
                {
                  afiNameList && afiNameList.map((item) => <option value={item.name}>{item.value}</option>)
                }
              </select>
            </div>

            <div className="input-group mb-2">
              <label htmlFor="listen.compare" className="input-group-text w-25">Compare</label>
              <select id="listen.compare" name="listen.compare" className="form-control"
                value={listen.compare || ""} onChange={handleChange}>
                <option value=""> </option>
                <option value="true">Alway true</option>
                <option value="false">Alway false</option>
                <option value="eq">Equal</option>
                <option value="gt">Greater than</option>
                <option value="gte">greater than or equal</option>
                <option value="lt">Less than</option>
                <option value="lte">Less than or equal</option>
                <option value="like">Like</option>
                <option value="in">In</option>
              </select>
              <input type="text" id="listen.test_value" name="listen.test_value" value={listen.test_value}
                placeholder="" className="form-control" onChange={handleChange} />
            </div>

            <div className="input-group mb-2">
              <label htmlFor="listen.source" className="input-group-text w-25">Update Source</label>
              <select value={listen.source || ""} id="listen.source" name="listen-source"
                className="form-control" onChange={handleChange}>
                <option value=""></option>
                <option value="user_profile">User Profile</option>
                <option value="custom_list">Custom List</option>
                <option value="user_address_list">User Address List</option>
                <option value="department_address_list">Department Address List</option>
                <option value="image_list">Image List</option>
              </select>
            </div>

            <div className="input-group mb-2">
              <label htmlFor="listen.fill_with" className="input-group-text w-25">Update list item</label>
              <select value={listen.fill_with || ""} id="listen.fill_with" name="listen.fill_with"
                className="form-control" onChange={handleChange}>
                <option value=""> </option>
                {
                  listen.source == 'user_profile' &&
                  <optgroup label="User Profile">
                    <option value="title">Title</option>
                    <option value="full_name">Full Name</option>
                    <option value="first_name">First Name</option>
                    <option value="last_name">Last Name</option>
                    <option value="email">Email</option>
                    <option value="phone">Phone</option>
                    <option value="cell">Cell</option>
                    <option value="fax">Fax</option>
                    {
                      custVars.map((item, idx) => <option value={idx}>{item}</option>)
                    }
                  </optgroup>
                }
                {
                  (listen.update_source == 'user_address_list' || listen.update_source == 'department_address_list') &&
                  <optgroup label="Addresses">
                    <option value="title">Title</option>
                    <option value="line1">Line 1</option>
                    <option value="line2">Line 2</option>
                    <option value="city">City</option>
                    <option value="state">State Abbriviate</option>
                    <option value="province">State/Province</option>
                    <option value="zip_code">Zip Code</option>
                    <option value="country">Country</option>
                    <option value="phone">Phone</option>
                    <option value="citystate">City State</option>
                    <option value="citystatezip">City, State Zipcode</option>
                  </optgroup>
                }
              </select>
            </div>

            <div className="input-group mb-2">
              <label htmlFor="listen.update_text" className="input-group-text w-25">Update text</label>
              <input type="text" id="listen.update_text" name="listen.update_text" value={listen.update_text}
                placeholder="New text value" className="form-control" onChange={handleChange} />
            </div>

            <div className="row mb-2">
              <div className="col">
                <label className="form-label" htmlFor="listen.qlango">Qlango</label>
                <textarea id="listen.qlango" name="listen.qlango" value={listen.qlango || ""}
                  className="form-control" onChange={handleChange}></textarea>
              </div>
            </div>
          </div>

          <div className="tab-pane fade" id="nav-interactive" role="tabpanel" aria-labelledby="nav-interactive-tab" tabIndex="0">
            <div className="card mb-2">
              <div className="card-header"><b>Interactive</b></div>
              <div className="card-body">
                <div className="form-group">
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.shape">Shape</label>
                    <select value={canvas.shape || ""} id="canvas.shape"
                      name="canvas.shape" className="form-control" onChange={handleChange}>
                      <option value="rect">Rectangle</option>
                      <option value="circle">Circle</option>
                      <option value="poly">Poly</option>
                      <option value="control">Form Control</option>
                    </select>
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas-x">X</label>
                    <input value={canvas.x} id="canvas.x" name="canvas.x"
                      type="number" step="10" className="form-control" onChange={handleChange} />
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.y">Y</label>
                    <input value={canvas.y} name="canvas.y" id="canvas.y" type="number"
                      step="10" className="form-control" onChange={handleChange} />
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.w">W</label>
                    <input value={canvas.w} id="canvas.w" name="canvas.w" type="number"
                      step="10" className="form-control" onChange={handleChange} />
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.h" >H</label>
                    <input value={canvas.h} id="canvas.h" name="canvas.h" type="number"
                      step="10" className="form-control" onChange={handleChange} />
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.scalex" >Scale X</label>
                    <input value={canvas.scalex} id="canvas.scalex" name="canvas.scalex" type="number"
                      step="10" className="form-control" onChange={handleChange} />
                  </div>
                  <div className="input-group mb-2">
                    <label className="input-group-text" htmlFor="canvas.scaley">Scale Y</label>
                    <input value={canvas.scaley} id="canvas.scaley" name="canvas.scaley" type="number"
                      step="10" className="form-control" onChange={handleChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-2">
              <div className="card-header"><b>Font</b></div>
              <div className="card-body">
                <div className="input-group mb-2">
                  <label className="input-group-text" htmlFor="font-family">Font Family</label>
                  <select value={font.family || ""} name="font.family" id="font.family" className="form-control"
                    onChange={handleChange}>
                    <option value=""></option>
                    <optgroup label="Web Fonts">
                      {
                        fontFamilyList.webFonts &&
                        fontFamilyList.webFonts.map((item) =>
                          <option value={item}>{item}</option>
                        )
                      }
                    </optgroup>
                    <optgroup label="Google Fonts">
                      {
                        fontFamilyList.googleFonts &&
                        fontFamilyList.googleFonts.map((item) =>
                          <option value={item}>{item}</option>
                        )
                      }
                    </optgroup>
                    <optgroup label="Custom Fonts">
                      {
                        fontFamilyList.customFonts &&
                        fontFamilyList.customFonts.map((item) =>
                          <option value={item}>{item}</option>
                        )
                      }
                    </optgroup>
                  </select>
                </div>

                <div className="input-group mb-2">
                  <label className="input-group-text" htmlFor="font.size">Font Size</label>
                  <input type="text" list="fontSizeList" value={font.size} id="font.size" name="font.size"
                    className="form-control" onChange={handleChange} />
                  <datalist id="fontSizeList">
                    <option value="10" />
                    <option value="12" />
                    <option value="14" />
                    <option value="16" />
                    <option value="18" />
                    <option value="20" />
                  </datalist>
                  <span className="input-group-text">px</span>
                </div>

                <div className="input-group mb-2">
                  <label className="input-group-text" htmlFor="font.weight">Font Weight</label>
                  <select value={font.weight ? font.weight : ""} id="font.weight" name="font.weight" className="form-control"
                    onChange={handleChange} >
                    <option value=""> </option>
                    <option value="normal">Normal</option>
                    <option value="bold">Bold</option>
                  </select>
                </div>
                <div className="input-group mb-2">
                  <label className="input-group-text" htmlFor="font.color">Color</label>
                  <input value={font.color} id="font.color" name="font.color" className="form-control h-auto" type="color"
                    onChange={handleChange} />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }
}
