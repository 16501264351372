import { toast } from "react-toastify";

/**
 * Validate Dom fields 
 * Next sibbling element must be a <div class="invalid-feedback"></div>
 * Validate form fields
 * @param [string] fieldIds
 * @return list of valid fields
 */
export const FieldValidation = (fieldIds) => {
  let frmValid = true;
  let frmData = {};
  fieldIds.map((id) => {
    const el = document.getElementById(id);
    if (el) {
      el.classList.remove('is-invalid');
      if (!el.checkValidity()) {
        el.classList.add('is-invalid');
        el.nextSibling && el.nextSibling.classList.replace('valid-feedback', 'invalid-feedback');
        frmValid = false;
      }
      else {
        el.classList.replace('is-invalid', 'is-valid');
        el.nextSibling && el.nextSibling.classList.replace('invalid-feedback', 'valid-feedback');
        frmData[el.name] = el.value;
      }
    }
  });
  return { result: frmValid, "data": frmData };
};

/**
 * Group array
 */
export const groupBy = (rs, key) => {
  if (rs) {
    return rs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
  return [];
}

/**
 * Format file size
 * @param int value 
 * @returns 
 */
export const FormatFileSize = (value) => {
  return '2M';
}


export const ShowToast = (message, logged) => {
  if (message.includes("Success")) {
    toast.success(logged, {
      position: toast.POSITION.TOP_RIGHT,
      role: "alert",
    });
  }
  else if (message.includes("Error")) {
    toast.error(logged, {
      position: toast.POSITION.TOP_RIGHT,
      role: "alert",
    });
  }
}

/**
 * Export to csv from data
 * @param {*} headers - { fieldname: title, ... }
 * @param [*] data - [{ fieldname: value, ... }, ... ]
 * @param string filename (no extention). E.g. prebilling-2023-01
 */
export function ExportCsvFile(headers, data, filename) {
  let csv = [];
  if (headers) {
    csv.push(Object.values(headers).join(","));
    // console.log(csv);

    const cols = Object.keys(headers);
    data.map((item) => {
      const remain = cols.map(key => '"' + item[key] + '"');
      csv.push(remain.join(","));
    });
  }

  const outFilename = filename + '.csv' || 'export.csv'
  var blob = new Blob([csv.join("\n")], { type: 'text/csv;charset=utf-8;' })
  var link = document.createElement('a')
  // Browsers that support HTML5 download attribute
  if (link.download !== undefined) {
    var url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', outFilename)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}

export function addLineBreak(str) {
  return str.split('\n').map((substr) => (<>{substr} <br /></>));
};

export function makeId(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
