const appConstant = {
  // Nationwide-Agency
  CUSTOMER_ID: 1913,
  // Product Category
  PRODUCT_CATEGORY_ID: 2220,
  // UPS Ground
  DELIVERY_METHOD_ID: 1,
  // Paid on invoice
  PAYMENT_METHOD_ID: 1,
  // 
  CUSTOMER_PROFILE: 'PROFILE',
  // Subdirecory
  DOCUMENT_ROOT: '/agencyjourney',
  // Base URL
  // BASE_URL = "https://staging.petinsurancerewards.com/agencyjourney/api",
  BASE_URL: "https://staging.petinsurancerewards.com/agencyjourney/api"
}
export default appConstant;