import { useState, useEffect, useMemo } from "react";
import { Modal, Card } from 'react-bootstrap';
import { addLineBreak } from "common/functions";
import appConstant from "constant/index";

export default function ProductCheckbox({ product, itemInCart, addItem, removeItem, remainPoint }) {

  const handleToggleItem = (e) => {
    e.stopPropagation();

    if (itemInCart(product.id)) {
      removeItem(product);
    }
    else {
      addItem({
        id: product.id,
        product_category_id: product.product_category_id,
        points: product.points,
        name: product.name
      });
    }
  }

  const handleImageError = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/product/0.jpg';
  };

  
  return (remainPoint < product.points) ?
    (
      <div className="m-3 cursor-pointer border border-2 border-dark-subtle" style={{ "opacity": 0.5 }}>
        <div className="row text-end">
          <div className="col text-end" style={{ "marginRight": "2px", "marginTop": "0px" }}>
            <img
              src={itemInCart(product.id) ? appConstant.DOCUMENT_ROOT + "/dist/img/checkbox_blue.jpg" : appConstant.DOCUMENT_ROOT + "/dist/img/checkbox_white.jpg"}
              className="" style={{ "width": "22px" }} />
          </div>
        </div>
        <div className="pt-0 text-center">
          <a role="button" className="btn m-2 p-0" >
            <img src={appConstant.DOCUMENT_ROOT + "/dist/img/product/" + product.id + ".jpg"} className="img-fluid" style={{"maxWidth": "250px"}} onError={handleImageError} />
            <div className="fs-8 fw-bold" style={{ "minHeight": "5em" }} ><b>{addLineBreak(product.name)}</b></div>
          </a>
        </div>
      </div>
    ) :
    (
      <div className="m-3 cursor-pointer border border-2 border-dark-subtle" style={{ "minWidth": "220px" }} >
        <div className="row text-end">
          <div className="col text-end" style={{ "marginRight": "2px", "marginTop": "0px" }}>
            <img
              onClick={handleToggleItem}
              src={itemInCart(product.id) ? appConstant.DOCUMENT_ROOT + "/dist/img/checkbox_blue.jpg" : appConstant.DOCUMENT_ROOT + "/dist/img/checkbox_white.jpg"}
              className="" style={{ "width": "22px" }} />
          </div>
        </div>
        <div className="pt-0 text-center">
          <a role="button" className="btn m-3 p-0" onClick={handleToggleItem}>
            <img src={appConstant.DOCUMENT_ROOT + "/dist/img/product/" + product.id + ".jpg"} className="img-fluid" style={{ "maxWidth": "250px" }} onClick={handleToggleItem} onError={handleImageError} />
            <div className="fs-8" style={{ "minHeight": "5em" }}><b>{addLineBreak(product.name)}</b></div>
          </a>
        </div>
      </div>
    );
}