import React, { useState, useEffect } from "react";
import appConstant from "constant/index";
import { Link, useNavigate } from 'react-router-dom';

function Share(props) {
  const navigation = useNavigate();
  const { getProfile, logout } = props.useSession();
  const [user, setUser] = useState(getProfile());

  useEffect(() => {
    document.title = 'Home';

  }, []);

  const handleNavigation = (ev) => {
    ev.stopPropagation();
    navigation(appConstant.DOCUMENT_ROOT);
  }

  /*
  const displayWebinarDlg = (ev) => {
    ev.stopPropagation();
    let dlg = document.getElementById('modal-webinar');
    let mod = bootstrap.Modal.getInstance(dlg);
    if (mod) {
      mod.show();
    } else {
      new bootstrap.Modal(document.getElementById("modal-webinar"), {
        backdrop: "static",
        keyboard: false
      }).show();
    }
  }
  */
  
  return (
    <>
      <div className="row">
          <div className="col text-center no-vs-search">
            <img src={appConstant.DOCUMENT_ROOT + "/dist/img/header.jpg"} alt="" className="img-fluid w-100" />
          </div>
      </div>

      <div className="text-center bg-light p-3">
        <div className="row mt-4 justify-content-around">
          {
            /*
            <div className="col-auto text-center">
              <button type="button" className="btn btn-primary px-4 py-3 mt-2"
                onClick={displayWebinarDlg} >Complete webinars</button>
          </div>
          */
          }
          <div className="col-5 text-center ">
            <a role="button" className="btn" onClick={() => navigation(appConstant.DOCUMENT_ROOT + "/history")} title="View Mission History">
              <figure className="no-vs-search">
                <img className="w-50" src={appConstant.DOCUMENT_ROOT + "/dist/img/mission_history.png"} title="View Mission History" />
                <figcaption className="fm-sans fw-bold fs-5 text-blue text-center mt-3"><u>View Mission History</u></figcaption>
              </figure>
            </a>
          </div>
          <div className="col-5 text-center">
            <a role="button" className="btn" onClick={() => navigation(appConstant.DOCUMENT_ROOT + "/redeem")} title="Redeem Secret Agent Bucks">
              <figure className="no-vs-search">
                <img className="w-50" src={appConstant.DOCUMENT_ROOT + "/dist/img/agent_buck.png"} title="Redeem Secret Agent Bucks" />
                <figcaption className="fm-sans fw-bold fs-5 text-blue text-center mt-3"><u>Redeem Secret Agent Bucks</u></figcaption>
              </figure>
            </a>
          </div>
        </div>

      </div>
      
      <div className="p-5" style={{ backgroundColor: "#d6f0ed"}}>
        <h3 className="fm-gotham-bold text-blue text-center mt-4">
          Your mission, should you choose to accept it
        </h3>
        <h5 className="mt-3 text-center">
          Complete all five assignments in the Mission Pet Paw-sible Journey.
        </h5>
        <h4 className="fm-sans fw-bold text-center mt-5">
          How it works
        </h4>
        <h5 className="text-center">
          Earn Secret Agent Bucks by attending webinars and completing activities,
          then redeem your Secret Agent Bucks for fantastic prizes.* This series of webinars is engineered to help
          you connect with new customers and deepen existing relationships, so you can complete any
          pet mission with ease.
        </h5>
        <h4 className="fm-sans fw-bold text-center mt-5">
          Your first mission
        </h4>
        <h5 className="mb-3 text-center">
          Earn top secret clearance by registering and completing the ambassador training webinar.
          If you have any questions about Mission Pet Paw-sible, email us at <a href="mailto:petprogram@nationwide.com">petprogram@nationwide.com</a>
        </h5>

        <div className="mt-5 fs-8 text-center">
          *No purchase necessary. Void where prohibited. Program not available in Washington.
          Agency must be a current participant of the Nationwide Pet Insurance Referral Program to qualify for reward.
          By participating, you are agreeing to the <a href="https://petinsurancerewards.com/agencyjourney/programrules.html" target="_blank" title="program rules">program rules</a>.
        </div>

      </div>


      <div class="modal" tabindex="-1" id="modal-webinar">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="mb-3 text-center">
                To schedule a webinar, email us at<br />
                <a href="mailto:petprogram@nationwide.com">petprogram@nationwide.com</a>
              </div>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>
    </>
  );

}
export default Share;
