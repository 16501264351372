import React, { useState, useEffect, useCallback } from "react";

import Graphic from "control/afi/element/Graphic";
import Select from "control/afi/element/Select";
import ImageList from "control/afi/element/ImageList";
import Number from "control/afi/element/Number";
import Tel from "control/afi/element/Tel";
import Email from "control/afi/element/Email";
import Url from "control/afi/element/Url";
import Date from "control/afi/element/Date";
import Text from "control/afi/element/Text";
import Label from "control/afi/element/Label";
import Progress from "control/afi/element/progress";
import moment from 'moment';

/**
 * Form Template
 * @param object afiProvider
 * @param string formName - 
 * @param function getData - call back function to get data 
 * @param handleAfiChange, handleAfiClick
 */
export default function AfiForm({ afiProvider, getCallback }) {
  const [validate, setValidate] = useState(0);
  const [formChanged, setformChanged] = useState();
  // current page
  const [activePageIdx, setActivePageIdx] = useState(0);

  /**
   * Auto grid
   * @return [["", ""],] - page layout of form 
   */
  const getPagination = () => {
    // Break down to chunks
    const afiKeys = Object.keys(afiProvider.afis);
    let pages = [];
    let rows = [];
    const colSize = afiProvider.afiLayout.form_grid[1];    // Chunk of cols [5, 1]
    for (let ii = 0; ii < afiKeys.length; ii += colSize) {
      // Collecting rows
      rows.push(afiKeys.slice(ii, ii + colSize));
      if (rows.length >= afiProvider.afiLayout.form_grid[0]) {
        // Move row to page
        pages.push(rows);
        rows = [];
      }
    }
    pages.push(rows);
    return pages;
  }

  /**
   * Render form field
   * @param string afiInput
   */
  const renderFormField = (afiInput) => {
    const ui = afiProvider.getAfiValue(afiInput, "display.ui_type");
    return (
      ui == "text" && (
        <Text name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "date" && (
        <Date name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "graphic" && (
        <Graphic name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "select" && (
        <Select name={afiInput}
          afiProvider={afiProvider}
          setformChanged={setformChanged}
          options={afiProvider.getAfiContent(afiInput)}
        />
      ) ||
      ui == "number" && (
        <Number name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "tel" && (
        <Tel name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "email" && (
        <Email name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "url" && (
        <Url name={afiInput} afiProvider={afiProvider} />
      ) ||
      ui == "button" && (
        <Button name={afiInput} afiProvider={afiProvider}
          handleAfiClick={getCallback(afiInput)} />
      )
    )
  };

  /**
   * @param string col - field name 
   * @returns 
   */
  const renderCol = (fieldname) => {
    // debugger;
    return (
      <div key={"form-col-" + fieldname} className={afiProvider.getAfiValue(fieldname, "display.hidden") ? "d-none" : "col"}  >
        {
          afiProvider.afiLayout.form_inline == 1 && (
            <div className="row mb-2">
              <div className="col-3" >
                <Label name={fieldname} afiProvider={afiProvider} />
              </div>
              <div className="col">
                {renderFormField(fieldname)}
                {afiProvider.getAfiValue(fieldname, "display.hint") && <div className="form-text">{afiProvider.getAfiValue(fieldname, "display.hint")}</div>}
              </div>

            </div>
          ) || (
            <>
              <Label name={fieldname} afiProvider={afiProvider} />
              {renderFormField(fieldname)}
              {afiProvider.getAfiValue(fieldname, "display.hint") && <div className="form-text">{afiProvider.getAfiValue(fieldname, "display.hint")}</div>}
            </>
          )
        }
      </div>
    )
  };

  /**
   * @param [string] row 
   */
  const renderRow = (row, idx) => (
    <div className="row" key={"form-row-" + idx}>
      {
        row.map((col) => renderCol(col))
      }
    </div>
  );

  const handleSubmit = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    alert("Validate Form");

    return false;
  };

  /**
   * Render form bases on rows and colums in page
   * @param [*] page - [ [afiname, afiname ], [afiname, afiname ] ] 
   * @returns 
   */
  const renderForm = useCallback((page, pageIdx) => {
    //debugger;
    return (
      <form key={"form-" + page} id="prod_custom" name="prod_custom" method="post" onSubmit={handleSubmit} >
        <div className={"card card-body " + (pageIdx == activePageIdx ? "" : "d-none")}>
          {
            page.map((row, idx) => renderRow(row, idx))
          }
        </div>
      </form>
    )
  }, [activePageIdx, formChanged]);

  if (afiProvider.afis) {
    const pagination = getPagination();
    return (
      <div className="">
        {
          pagination && pagination.length > 1 &&
          <div className="mb-2">
              <Progress pages={pagination.length} activePage={activePageIdx} setActivePageIdx={setActivePageIdx} />
          </div>
        }
        {
          pagination.map((page, idx) => renderForm(page, idx))
        }
      </div>
    );
  }
}
