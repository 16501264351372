import { useState, useEffect, useMemo, useCallback } from "react";
import DataTable from "react-data-table-component";

/**
 * Display table. 
 * Use array.unshift to prepend header and styles
 * Use array.push to append footer
 * @param {*} data - [
 *  // First row contains object for datatable configuration
 *  {id: {name: "Order#", sortable: true, width: "20%", click:"handleCellClick", footer: "sum"}, ... } 
 * ] 
 * @returns 
 */
export default function Table({ data, handleRowClick }) {

  const handleRowSelected = useCallback(state => {
  }, []);

  if (data) {
    const customStyles = {
      rows: {
        style: {
          minHeight: '40px', // override the row height
        },
      },
      headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
        },
      },
      cells: {
        style: {
          paddingLeft: '4px', // override the cell padding for data cells
          paddingRight: '4px',
        },
      },
    };

    const buildColumns = () => {
      const top = data.slice(0, 1)[0];
      let cols = [];
      for (let key in top) {
        cols.push( { ...top[key], selector: (row) => row[key] } );
      }
      return cols;
    }

    const filteredItems = data.filter((item, idx) => {
      return idx > 0
    });

    const columns = buildColumns();
    
    return  (
      <DataTable
        paginationPerPage={50}
        striped={true}
        columns={columns}
        data={filteredItems}
        defaultSortFieldID={1}
        pagination
        responsive
        // subHeader
        // subHeaderComponent={subHeaderComponentMemo}
        // selectableRows
        selectableRowsHighlight
        onSelectedRowsChange={handleRowSelected}
        fixedHeader
        customStyles={customStyles}
        highlightOnHover
        // actions={actionsMemo}
      />
    );
  }


}
