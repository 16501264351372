import { useState, useEffect } from "react";



export default function ImageList({ afi, handleAfiChange }) {

  const handleChange = (ev) => {
    // Check required with afi.is_required

    // Check format with afi.validation
    handleAfiChange(ev);
  }

  return (
    <div>

    </div>
  );
}
