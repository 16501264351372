import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import Product from './Product';
import appConstant from "constant/index";


function Summary({ user, totalPoint }) {
  const navigate = useNavigate();


  useEffect(() => {
    document.title = 'Reward - History';
    window.scrollTo(0, 0);
  }, []);

  const handleImageError = (e) => {
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/noimages.png';
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-5 text-end">
          <img src={appConstant.DOCUMENT_ROOT + "/dist/img/bootbuck.jpg"} style={{ "width": "70%", "maxWidth": "140px" }} />
        </div>
        <div className="col-7 text-center my-auto">
          <div className="fw-bold fs-5 fst-italic text-capitalize" style={{ color: "#0047BB"}}>{user.first_name} {user.last_name}</div>
          <div className="fw-bolder fm-sans fs-4">Welcome to Mission Control</div>
          <div className="fm-sans mt-1">Track your Mission Pet Paw-sible progress</div>
          <div className="fm-sans mt-1 fst-italic"><strong>{totalPoint.total_point}</strong> Secret Agent Bucks</div>
        </div>
      </div>

      <div className="row mt-4 mx-3">
        <h3 className="col-12 fw-bolder fm-sans my-2 f2">
          Activities
        </h3>
        {
          user.journeys && user.journeys.map((reward, idx) => {
            if (reward.journey_id > 2 ) {
              return (
                <div className="col-12 card card-body bg-body-secondary py-2 mb-2" key={idx}>
                  <h2 className="fw-bold fs-2 ">{reward.name} <span class="badge bg-success ms-4">Completed</span></h2>
                  <h6 className="fs-2">Secret Agent Bucks: <span className="text-error">{reward.points}</span></h6>
                </div>
              )
            }
          })
        }
        <div className="col-12 text-center mt-4">
          <a href="#" onClick={() => navigate("/agencyjourney")} className="fs-2">Back to Home</a>
        </div>
      </div >
    </>
  );

}
export default Summary;
