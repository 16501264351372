import axios from "axios";
import appConstant from "constant/index";


export default (beforeSend, afterResponse) => {
  let token = null;
  const profile = sessionStorage.getItem("profile");
  if (profile) {
    token = JSON.parse(profile).token;
    // console.log(token);
  }

  const instance = axios.create({
    // baseURL: "https://admin.psggo.com/api",
    baseURL: appConstant.BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Authorization": `Bearer ${token}`
    }
  });

  if (beforeSend) {
    // Add a request interceptor
    instance.interceptors.request.use(function (config) {
      // Do something before request is sent
      beforeSend();
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });
  }

  if (afterResponse) {
    // Add a response interceptor
    instance.interceptors.response.use(function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      afterResponse();
      return response;
    }, function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    });
  }

  return instance;
}
