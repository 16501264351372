import React, { useState, useRef, useEffect, useCallback, useMemo, useContext } from 'react'
import { Link, useParams } from 'react-router-dom';
import AfiList from "control/afi/element/List";
import AfiSetup from "control/afi/template/Setup";
import AfiProvider from "context/AFIContext";
import ProductService from "api/precision/ProductService";
import ListitemService from "api/precision/ListitemService";


// http://localhost:3000/agencyjourney/product/customize-setup/142093

export default function CustomizeSetup({ useSession }) {

  const { productId } = useParams(0);
  const [product, setProduct] = useState();
  const [curAfiName, setCurAfiName] = useState();
  const [afiValueChanged, setAfiValueChanged] = useState(0);
  
  useEffect(() => {
    
    document.title = 'Setup Parameters';
    ProductService.query(productId, ["afis"])
      .then((response) => {
        if (response.data.result == 1) {
          // console.debug(response.data.data.afis);
          setProduct({
            id: response.data.data.id,
            name: response.data.data.name,
            customer_id: response.data.data.customer_id,
          });
          AfiProvider.saveAfis(response.data.data.afis);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });

    ListitemService.query("validations")
      .then((response) => {
        if (response.data.result == 1) {
          AfiProvider.saveValidations(response.data.data);
          // console.debug(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }, []);

  const handleListChange = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.target.classList.add("active");
    setCurAfiName(ev.target.dataset.value);
  }


  // Get 1 afi object when any value changed
  /*
  const curAfi = useMemo(() => {
    return AfiProvider.getAfi(curAfiName);
  }, [curAfiName, afiValueChanged]);
  */
  
  // Save current afi into server
  const saveAfi = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    const afi = {};
    afi[curAfiName] = AfiProvider.getAfi(curAfiName);
    // console.debug(afi);
    ProductService.updateAfis(product.id, product.customer_id, afi)
      .then((res) => {
        if (response.data.result == 1) {
          alert(curAfiName + " has been updated.")
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }

  // Save all afis into server
  const saveAfis = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    // console.debug(afi);
    ProductService.updateAfis(product.id, product.customer_id, AfiProvider.afis);
  }

  const startOver = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ProductService.queryAfis(product.id, product.customer_id, 1)
      .then((response) => {
        if (response.data.result == 1) {
          AfiProvider.saveAfis(response.data.data.afis);
          setAfiValueChanged(afiValueChanged + 1);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  }

  if (AfiProvider.afis) {
    return (
      <div className="row m-2">
        <div className="col-3">
          <h5>Parameter List</h5>
          <AfiList listItems={AfiProvider.getAfiList()} activeValue={curAfiName} handleListChange={handleListChange} />
        </div>
    
        <div className="col-9 ">
          {
            curAfiName && (
              <>
                <AfiSetup curAfiName={curAfiName} afiProvider={AfiProvider} />
                <div className="row mt-3">
                  <div className="col-12 text-end">
                    <button type="button" className="btn btn-danger me-2" onClick={startOver}>Start Over</button>
                    <button type="button" className="btn btn-primary me-2" onClick={saveAfis}>Save All</button>
                    <button type="button" className="btn btn-primary" onClick={saveAfi}>Save {curAfiName}</button>
                  </div>
                </div>
              </>
            )
          }
        </div>

      </div>
    )
  }

}
