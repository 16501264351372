import { useState, useEffect, useMemo, useCallback } from "react";
import "styles/progress_circle.css";

/**
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Progress({ pages, activePage, setActivePageIdx }) {

  const handleChange = (ev, idx) => {
    ev.preventDefault();
    ev.stopPropagation();
    // Check format with afi.validation
    setActivePageIdx(idx);
  }

  /**
   * Convert an integer to array of numbers
   * @param {*} num 
   * @returns 
   */
  const number2Array = (num) => {
    let rv = [];
    for (let ii = 0; ii < num; ii++) {
      rv.push(ii);
    }
    return rv;
  }

  const myClass = "d-flex gap-3 justify-content-center align-items-center";

  //debugger;
  if (pages) {
    const ranges = number2Array(pages);
    return (
      <div className="paging-steps d-flex flex-column flex-md-row justify-content-md-center align-items-start align-items-md-center mt-3 mb-4">
        {
          ranges.map((n, idx) => (
            <div className={myClass} key={"progress-" + idx}>
              <a role="button" className={myClass + " step-num" + (idx == activePage ? " " : " inactive")} onClick={(ev) => handleChange(ev, idx)}>{idx + 1}</a>
            </div>
          ))
        }
      </div>
    )
  };
}
