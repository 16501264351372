import http from "./Endpoint";

/**
 * List items categories:
 * [
 *  "user_profile",
 *  "custom_list",
 *  "user_address_list",
 *  "department_address_list",
 *  "image_list",
 *  "validations",
 * 
 * ]
 */
class ListitemService {

  /**
   * Get a list of items
   * @param string categoryName 
   * @param string fillWith - key name from dataset
   * @param string ownerId - filter a specific item in the list
   * @returns 
   */
  query(categoryName, fillWith, ownerId) {
    return http().post("/listitem/query", { source: [ categoryName ], fill_with: fillWith, owner_id: ownerId });
  }
 
  /**
   * Get multiple list items
   * @param [string] categories 
   */
  queryMulitple(categories) {
    return http().post("/listitem/query", { category: categories });
  }

}

export default new ListitemService();