import { useState, useEffect } from "react";

/**
 * Display afi List
 * @param {*} listItems - [
 *  { name: , value }
 * ]
 * @param strign activeValue - activeValue == item.value => setActive
 */
export default function List({ listItems, activeValue, handleListChange }) {

  // debugger;
  return listItems && (
    <ul className="list-group">
      {
        listItems.map((item, idx) => {
          const itemclass = (activeValue == item.value) ? "list-group-item list-group-item-action active" : "list-group-item list-group-item-action";
          return (<a role="button" className={itemclass}
            key={idx}
            onClick={handleListChange}
            data-name={item.key} data-value={item.value}>{item.value}</a>)
        })
      }
    </ul>
  );
}
