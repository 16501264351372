import { useState, useEffect, useMemo, useCallback } from "react";


export default function Url({ name, afiProvider }) {
  const [userVal, setUserVal] = useState(afi.user_value);
  const afi = afiProvider.getAfi(name);

  const handleChange = (ev) => {
    // Check required with afi.is_required
    setUserVal(ev.target.value);
    // Check format with afi.validation
    const triggers = afiProvider.updateAfiValue(ev.target.name, "user_value", ev.target.value);
  }

  //debugger;
  return afi && (
    <input type="url" name={afi.name}
      id={afi.name}
      className={afi.display.class ? afi.display.class : "form-control"}
      placeholder={afi.display.placeholder}
      value={userVal}
      onChange={handleChange}
      readOnly={afi.display.readonly}
      required={afi.display.required}
      disabled={afi.display.disabled}
      pattern="https://.*"
    />
  );
}
