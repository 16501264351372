import { useState, useEffect, useMemo } from "react";
import { Modal, Card } from 'react-bootstrap';
import appConstant from "constant/index";

export default function ZoomBox({ proof }) {
  const [curImgIdx, setCurImgIdx] = useState();

  const transalteZoom = (e) => {
    e.stopPropagation();
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = (e.pageX - left) / width * 100;
    const y = (e.pageY - top) / height * 100;
    e.target.style.backgroundPosition = `${x}% ${y}%`;
    e.target.style.backgroundSize = "auto";
    document.getElementById("zoom-image").style.opacity = 0;
  }

  const handleReset = (e) => {
    e.stopPropagation();
    const { left, top, width, height } = e.target.getBoundingClientRect();
    e.target.style.backgroundPosition = `-${left}% -${top}%`;
    e.target.style.backgroundSize = "cover";
  }

  const curImage = useMemo(() => {
    let url = appConstant.BASE_URL + proof.path + "/" + proof.files[0];
    if (curImgIdx == "1") {
      url = appConstant.BASE_URL + proof.path + "/" + proof.files[1];
    }
    return (
      <figure onMouseMove={transalteZoom} onMouseLeave={handleReset}
        style={{ "backgroundImage": `url(${url})`, "backgroundRepeat": "no-repeat" }}>
        <img id="zoom-image" className="img-fluid" src={url}
        style={{ "display": "block", "width": "200%", "pointerEvents": "none" }}
      />
      </figure>
    )
  });

  const handleCurIdx = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    setCurImgIdx(ev.target.value);
  }

  return (
    <>
    {
      curImage        
    }
    <nav aria-label="Page navigation text-center">
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link" href="#" aria-label="Previous">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
          <li className="page-item"><button type="button" className="page-link" value="0" onClick={handleCurIdx}>1</button></li>
          <li className="page-item"><button type="button" className="page-link" value="1" onClick={handleCurIdx}>2</button></li>

        <li className="page-item">
          <a className="page-link" href="#" aria-label="Next">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
    </>
  );
}
