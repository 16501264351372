import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import Header from "../navigation/Header";
import Footer from "../navigation/Footer";
import { FieldValidation } from "common/functions";
import MemberService from "api/agency/MemberService";
import appConstant from "constant/index";


// Try this: kelly@isofco.com
export default function Login({ useSession } ) {

  const location = useLocation();
  const { loginEmailOnly, isLogin } = useSession();
  const [ loginError, setLoginError ] = useState(false);

  // debugger;
  function handleLogin(e) {
    e.preventDefault();
    let res = FieldValidation(["email"]);
    if (res.result) {
      sessionStorage.setItem('cartItems', "[]");
      loginEmailOnly(res.data.email)
        .then((res) => {
          if (isLogin()) {
            document.location = appConstant.DOCUMENT_ROOT;
          }
          else {
            setLoginError(true);
          }
        });
    }
  }

  useEffect(() => {
    // debugger;
    document.title = 'Login';
    const params = new URLSearchParams(location.search);
    if (params.has("utm_psg")) {
      // debugger;
      const utm = params.get("utm_psg");
    }

  }, []);

  return (
    <div className="">
      <Header useSession={useSession}  />      
      <section className="container">
        <div className="row" style={{ backgroundColor: "#0047BB" }}>

          <div className="col-lg-7 col-md-12 col-sm-12 text-center pt-5"  >
            <div className="text-center text-white fs-2 lh-sm">
              Enter your agent email associated <br />with Mission Pet Paw-sible
            </div>

            <div className="row pt-4 justify-content-around">
              <div className="col-6 text-center">
                <input
                  className="p-1 text-center"
                  id="email" name="email" type="email"
                  placeholder="Email" required
                  pattern=".+@.+\..+" />
                <div className="valid-feedback text-center" style={{ color: "orange" }}>Email address is invalid.</div>
              </div>
              {
                loginError && <div className="valid-feedback" style={{ display: "block", color: "orange" }} >Your email address doesn't exist in our system.</div>
              }
            </div>

            <div className="row mt-3 mb-2 justify-content-around">
              <div className="col text-center">
                <button type="button" className="btn-green px-4 py-1"
                  onClick={handleLogin}>Submit</button>
              </div>
            </div>
          </div>
          
          <div className="col no-vs-search text-lg-end text-center p-4 p-md-4 p-lg-0 ">
            <img src={appConstant.DOCUMENT_ROOT + "/dist/img/group.jpg"} alt="Image" className="my-100" />
          </div>

          <Footer useSession={useSession} version={1} />
        </div>
      </section>

    </div>
  );
}


