import { useState, useEffect } from "react";
import { FieldValidation } from "common/functions";

/**
 * Display address box - Parent must provide address change function
 * @param {*} address - {
 * }
 * @param function handleAddressChange
 * 
 */
export default function Address({ address, handleAddressChange }) {

  return (
    <div className="">
      <div className="row">
        <label htmlFor="line1" className="col-2 d-none d-md-block d-lg-block col-form-label">Street</label>
        <div className="col-sm-12 col-md-10 col-lg-10">
          <input type="text" name="line1" id="line1" className="form-control" placeholder="Street" required
            value={address.line1} onChange={handleAddressChange} />
        </div>
      </div>

      <div className="row mt-2">
        <label htmlFor="city" className="col-2 d-none d-md-block d-lg-block col-form-label">City</label>
        <div className="col-sm-12 col-md-10 col-lg-10">
          <input type="text" name="city" id="city" className="form-control" placeholder="City" required
            value={address.city} onChange={handleAddressChange}
          />
        </div>
      </div>

      <div className="row mt-2">
        <label htmlFor="state" className="col-2 d-none d-md-block d-lg-block col-form-label">State</label>
        <div className="col-sm-5 col-md-4 col-lg-4">
          <select className="form-select" id="state" name="state" required
            value={address.state} onChange={handleAddressChange}
          >
            <option value="" > State </option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DC">District Columbia</option>
            <option value="DE">Delaware</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
          </select>
        </div>
        <label htmlFor="zip_code" className="col-2 d-none d-md-block d-lg-block col-form-label">Zip code</label>
        <div className="col-sm-7 col-md-4 col-lg-4">
          <input type="text" name="zip_code" id="zip_code" className="form-control" placeholder="Zip code" required
            value={address.zip_code} onChange={handleAddressChange}
          />
        </div>
      </div>
    </div>
  );
}
