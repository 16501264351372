import React from 'react';
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { createRoot } from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import { SessionProvider, useSession } from "context/SessionContext";
 
const root = document.getElementById('root');
const rootElement = (
  <React.StrictMode>
    <SessionProvider>
      <BrowserRouter basename='/'>        
        <App useSession={useSession} />          
      </BrowserRouter>
    </SessionProvider>
</React.StrictMode>
);

const container = createRoot(root);
container.render(rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// serviceWorker.unregister();