import React, { useEffect, useState, Suspense } from "react";
import Index from "./components";
import { Route, Routes, Location } from 'react-router-dom';
import { ShoppingCartProvider, useShoppingCart } from "context/ShoppingCartContext";
import Login from 'components/support/Login';


function App(props) {
  const [loggedIn, setLoggedIn] = useState(false);
  const { isLogin } = props.useSession();

  useEffect(() => {
    // debugger;
    setLoggedIn(isLogin());
  }, [loggedIn]);

  // debugger;
  if (loggedIn) {
    return (
      <>
        <Suspense fallback={<div id="preloader">Loading ... </div>}>
          <div className="mx-auto" style={{ "maxWidth": "900px" }}>
            <ShoppingCartProvider >
              <Index useSession={props.useSession} useShoppingCart={useShoppingCart}  />
            </ShoppingCartProvider>
          </div>
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100 mx-auto" style={{ "maxWidth": "900px" }}>
        <Suspense fallback={<div id="preloader">Loading ... </div>}>
          <Routes>
            <Route path='*' element={<Login useSession={props.useSession} />} />
          </Routes>
        </Suspense>
      </div>
    );
  }
}

export default App;

