/**
 * Global context for session
 * To access global context: 
 * const { loginStandard, singleSignOn, logout, isLogin, getToken  } = useSession();
 */
import React, { createContext,  useContext } from 'react';
import swal from "sweetalert";
import http from "api/agency/Endpoint";
// Use this for sensitive info like profile, etc

const SessionContext = createContext();

export function SessionProvider({ children }) {

  /**
   * For nationwide friends and family
   * @param string email
   */
  const loginEmailOnly = (email) => {
    const loginFormData = new FormData();
    loginFormData.append("email", email);
    http().post("/login", loginFormData)
      .then((res) => {
        if (res.data.result == 1) {
          sessionStorage.setItem('profile', JSON.stringify(res.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return new Promise(resolve => {
      setTimeout(() => resolve(), 500);
    });
  }

  /**
   * Login by user name and password
   * @param {*} username 
   * @param {*} password 
   * @returns 
   */
  const loginStandard = (username, password) => {
    const loginFormData = new FormData();
    loginFormData.append("username", username)
    loginFormData.append("password", password)
    http().post("/auth", loginFormData)
    .then( (res) => {
      if (res.data.result == 1) {
        sessionStorage.setItem("profile", JSON.stringify(res.data));
      }
      else {
        swal("Oops", "Invalid username or password", "error");
      }
    })
    .catch((error) => {
      console.log(error);
    });
    return new Promise(resolve => {
      setTimeout(() => resolve(isLogin()), 500);
    });
  }

  /**
   * Other method of login like address
   * @param {*} addressname 
   * @param {*} address 
   * @param {*} postalCode 
   */
  const loginAddress = (customerId, addressname, address, postalCode) => {
    const loginFormData = new FormData();
    loginFormData.append("customer_id", customerId);
    loginFormData.append("address_name", addressname);
    loginFormData.append("line1", address);
    loginFormData.append("zip_code", postalCode);

    http().post("/auth", loginFormData)
    .then((res) => {
      if (res.data.result === 1) {
        sessionStorage.setItem("profile", JSON.stringify(res.data.profile));
      }
      else {
        swal("Oops", "Invalid login credential", "error");
      }
    })
    .catch((error) => {
      console.log(error);
    });

    return new Promise(resolve => {
      setTimeout(() => resolve(isLogin()), 200);
    });
  }

/**
 * Login be a single signon token
 * @param string token 
 */
  const singleSignOn = (token) => {
    const loginFormData = new FormData();
    loginFormData.append("token", token);
    http().post("/auth", loginFormData)
      .then((res) => {
        if (res.data.result === 1) {
          sessionStorage.setItem("profile", JSON.stringify(res.data));
        }
        else {
          swal("Oops", "Invalid login credential", "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  /**
   * Refresh token in amount of time in ms
   * @param int timer 
   */
  const refreshToken = (timer) => {
    setTimeout(() => {
      loginStandard();
    }, timer);
  }

  const logout = () => {
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('memo');
  }

  /**
   * Check if user login
   * @returns boolean
   */
  const isLogin = () => {
    const str = sessionStorage.getItem('profile');
    return str? true : false;
  }

  /**
   * @returns {*} user profile
   */
  const getProfile = () => {
    const str = sessionStorage.getItem('profile');
    return JSON.parse(str);
  }

  const saveProfile = (profile) => {
    sessionStorage.setItem("profile", JSON.stringify(profile));
  }

  return (
    <SessionContext.Provider
      value={{
        loginEmailOnly,
        loginStandard,
        loginAddress,
        singleSignOn,
        logout,
        isLogin,
        refreshToken,
        getProfile,
        saveProfile
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSession() {
  return useContext(SessionContext);
}
