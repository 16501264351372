import http from "./Endpoint";

/**
 * Preorder service - different from product in a way that it preload data.list_items 
 * with user data
 */
class PreorderService {

  /**
   * Get preorder info by preorderId
   * @param int preorderId 
   * @param [string] includes - the data return  ["afis", "mailing_list", "product"] 
   * @returns 
   */
  queryPreorder(preorderId, includes) {
    const article = {
      "filter": {
        preorder_id: preorderId,
      },
      "return": includes
    }
    return http().post("/preorder/query", article);
  }

  /**
   * List preorders based on user Id
   * @param int customerId , productId. E.g: 2177, 217286
   * @param [string] includes - the data return  ["product"] 
   */
  list(customerId, userId) {
    const article = {
      filter: {
        customer_id: customerId,
        user_id: userId
      },
      "return": includes
    }
    return http().post("/preorder/list", article);
  }

  /**
   * Create a preorder with option of making proof
   * @param [*] data {
   * "customer_id":  1912,
   * "user_id": 10615,
   * "product_id": 139859,
   * "session_id": 12345,
   * "quantity": 200,
   * "description_user_1": null,
   * "description_user_2": null,
   * // Optional generate proof
   * "proof": {
   *     "confirmation_email": 0,
   *     // What are you going to do with the output 
   *     // PRINT, PROOF, DOWNLOAD_DOCUMENT, DOWNLOAD_CAMPAIGN, PROOF_SET, RECORD_SET, EMAIL_MARKETING, EMAIL_PREFLIGHT
   *     "job_type": "PROOF",
   *     // Basically this is output format
   *     // tell exmpie to create format - PDF, PDFO, JPG, RECORDSET, PS, VIPP, PPML, VDX, JOB_INFO
   *     "output_type": "JPG",
   *     // 1 = print, 2 = web, 4 = email
   *     "media_type": 1,
   *     "afis": {
   *         "Photo": "dataUrl",
   *         "CallToAction": "",
   *         "CTAInteractive": "Visit precisionservicesgroup.com"
   *     },
   *     "customs": null
   * }
   * }
   * @return - {
   *     "code": "RAH0Sgtgtu5VhXAG",
   *     "path": "\\\\staging\\websites\\core\/etc\/customers\/1417\/preorders\/604343\/pdf",
   *     "files": [
   *         "document_604343.pdf"
   *     ]
   * }
   */
  add(data, beforeSend, afterResponse) {
    const instance = http(beforeSend, afterResponse);
    return instance.post("/preorder/addPreorder", data);
  }


  /**
   * Update basic preorder information
   * @param [*] - {
   *   "preorder_id": 604328,
   * "quantity": 100
   * }
   */
  update(data) {
    return http().post("/preorder/updatePreorder", data);
  }

  /**
   * Generate proof
   * @param {*} data 
   * @returns 
   */
  proof(data) {
    const loginForm = new FormData();
    for (var key in data) {
      loginForm.append(key, data.key);
    }
    return http().post("/preorder/proof", loginForm);
  }

  /**
   * @param {*} data = {
   * // Place order from reuest form and preorder like see's 
   * "customer_id": 2177,
   * "user_id": 10615,
   * "output_type": "PROOF",
   * // Optional selected items, empty all items for user
   * "items": [
   *   { "preorder_id": 605658, "product_id": 144577 }
   *  ]
   * }
   */
  proofs(data) {
    return http().post("/preorder/proofs", data);
  }

}

export default new PreorderService();