import React, { useState, useMemo } from 'react';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { fabric } from 'fabric';
import "./editor.css"


export default function FabricPreview({ adors, updateAdorValue }) {

  const [text, setText] = useState('');
  const [selectedFontSize, setSelectedFontSize] = React.useState(30);
  const [selectedColor, setSelectedColor] = React.useState('black');
  const { selectedObjects, editor, onReady } = useFabricJSEditor();
  const img = document.createElement('img');

  const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
  img.src = deleteIcon;
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = 'blue';
  fabric.Object.prototype.cornerStyle = 'circle';

  // This function will be exposed to parent components
  const handleFontSizeChange = (size) => {
    setSelectedFontSize(size);
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'i-text') {
      activeObject.set('fontSize', size);
      editor.canvas.renderAll();
    }
  };

  const renderIcon = (ctx, left, top, styleOverride, fabricObject) => {
    var size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  };

  const handleColorChange = (color) => {
    debugger
    setSelectedColor(color);
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject && (activeObject.type === 'i-text' || activeObject.type === 'rect')) {
      activeObject.set('fill', color);
      editor.canvas.renderAll();
    }
  };

  const addRectangle = () => {
    const rect = new fabric.Rect({
      width: 100,
      height: 100,
      fill: selectedColor, // Use the selected background color
    });

    editor.canvas.add(rect);
  };

  const onAddText = (val) => {debugger
    const text = new fabric.IText(val , {
      left: 100,
      top: 120,
      fontFamily: 'Arial',
      fill: 'black',
      fontSize: 30,
    });

    text.on('selected', () => {
      setSelectedFontSize(text.fontSize);
      setSelectedColor(text.fill);
    });
    text.set({
      lockMovementX: true,
      lockMovementY: true,
    });
    editor?.canvas.add(text);
  };

  const onDelete = () => {
    editor?.deleteSelected();
    console.log(selectedObjects);
  };

  fabric.Object.prototype.controls.deleteControl = new fabric.Control({
    x: 0.5,
    y: -0.5,
    offsetY: 16,
    cursorStyle: 'pointer',
    mouseUpHandler: onDelete,
    render: renderIcon,
    cornerSize: 24,
  });

  const onAddCircle = () => {
    //console.log(editor);
    editor?.addCircle();
  };

  let color = 55;
  const onAddLine = () => {
    //console.log(editor);
    editor?.addLine();
  };
  //   const ImageUploader1 = ({ onImageChange }) => {
  //     return (
  //       <div>
  //      <input type="file" accept="image/*"  className="editorButton" onChange={onImageChange}>
  //      </input>
  //       </div>
  //     );
  //   };

  const handlePreview = ({ adors, updateAdorValue }) => {
    const dataURL = editor?.canvas.toDataURL({
      format: 'png',
      quality: 1,
    });

    const printWindow = window.open('', '_blank');
    const imgElement = `<img src="${dataURL}" onload="window.print()" />`;

    printWindow?.document.write('<html><head><title>Print Preview</title></head><body>');
    printWindow?.document.write(imgElement);
    printWindow?.document.write('</body></html>');
    printWindow?.document.close();
  };

  const handleImageChange = (e) => {
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = function () {
        const img = new fabric.Image(imgObj);
        editor?.canvas.add(img);
        editor?.canvas.renderAll();
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  /*text formatting */
  const handleUnderline = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'i-text') {
      activeObject.set('underline', !activeObject.underline);
      editor.canvas.renderAll();
    }
  };

  const handleBold = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'i-text') {
      activeObject.set('fontWeight', activeObject.fontWeight === 'bold' ? 'normal' : 'bold');
      editor.canvas.renderAll();
    }
  };

  const handleItalic = () => {
    const activeObject = editor?.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'i-text') {
      activeObject.set('fontStyle', activeObject.fontStyle === 'italic' ? 'normal' : 'italic');
      editor.canvas.renderAll();
    }
  };
  /*Cropping */

  const adorFields = useMemo(() => {
    // debugger;
    if (adors) {
      let rv = [];
      for (let key in adors) {
        const ador = adors[key];
        rv.push(
          <li>
            <a 
              className="dropdown-item" 
              href="#" 
              onClick={() => onAddText(ador.label)}
            >
              {ador.label}
            </a>
          </li>
        );
      }
      return rv;
    }
  }, [adors]);

  return (
    <div className="container mt-3">
      <div id="ador-page" className="input-group dropstart editorToolbar mb-1">
        <span class="form-control form-control-small">Page 1 Imran</span>
        <button type="button" class="btn dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
          <span class="visually-hidden">Toggle Dropdown</span>
        </button>

        <ul class="dropdown-menu w-100">
          {adorFields}
        </ul>
      </div>

      <div id="ador-text-field" className="input-group editorToolbar">
        <button className="editorButton" onClick={() => onAddText('Write your text')}>
          <img src="/sees/dist/img/svg/text.svg" />
        </button>
        <select
          className="editorButton w-auto"
          value={selectedFontSize}
          onChange={(e) => handleFontSizeChange(e.target.value)}
        >
          <option value="8">8</option>
          <option value="10">10</option>
          <option value="12">12</option>
          <option value="14">14</option>
          <option value="18">18</option>
          <option value="24">24</option>
          <option value="36">36</option>
        </select>

        <input
          type="color"
          className="editorButton p-2"
          value={selectedColor}
          onChange={(e) => handleColorChange(e.target.value)}
        />

        <button className="editorButton" onClick={handleBold}>
          <img src="/sees/dist/img/svg/bold.svg" />
        </button>

        <button className="editorButton" onClick={handleItalic}>
          <img src="/sees/dist/img/svg/italic.svg" />
        </button>

        <button className="editorButton" onClick={handleUnderline}>
          <img src="/sees/dist/img/svg/underline.svg" />
        </button>

      </div>

      <div id="ador-graphic-field" className="input-group editorToolbar">
        <button
          className="editorButton"
          onClick={() => document.getElementById("file-input").click()}
        >
          <img src="/sees/dist/img/svg/image_upload.svg" />
        </button>
        <input
          type="file"
          id="file-input"
          accept="image/*"
          onChange={handleImageChange}
          style={{ display: "none" }}
        />
      </div>

      <div id="ador-list-field" className="input-group editorToolbar">
        <label className="form-label">Address</label>
        <select className="form-control">
          <option> Full name </option>
        </select>
      </div>


      <FabricJSCanvas className="editorContent" onReady={onReady} />

      {/* <div className="btn-toolbar mt-3" role="toolbar">
        <div className="btn-group mr-2">
        </div>
        <div className="btn-group mr-2">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Font Size</span>
            </div>
            <input
              type="number"
              className="form-control"
              value={selectedFontSize}
              onChange={(e) => handleFontSizeChange(e.target.value)}
            />
          </div>
        </div>
        <div className="btn-group">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Color</span>
            </div>
            <input
              type="color"
              className="form-control"
              value={selectedColor}
              onChange={(e) => handleColorChange(e.target.value)}
            />
          </div>
        </div>
      </div> */}
    </div>
  );
};
