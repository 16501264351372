import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams } from 'react-router-dom';
import AfiProvider from "context/AFIContext";
import ProductService from "api/precision/ProductService";
import PreorderService from "api/precision/PreorderService";
import AfiForm from "control/afi/template/Form";
import ZoomBox from "control/afi/element/ZoomBox";
import ListitemService from "api/precision/ListitemService";

/**
 * Customize a product using form
 * http://localhost:3000/agencyjourney/product/customize-form/142093
 * @returns 
 */
export default function CustomizeForm({ useSession }) {
  const { getProfile } = useSession();

  const { productId } = useParams(0);
  const [product, setProduct] = useState();
  const [user, setUser] = useState(getProfile());
  const [showModal, setShowModal] = useState(false);
  // State to update Afi value
  const [afiValueChanged, setAfiValueChanged] = useState(0);
  // Proof information. { preorder_id: , code: , path, files: [] } 
  const [proof, SetProof] = useState();

  useEffect(() => {
    // debugger;
    document.title = 'Customize Product';
    
    ProductService.queryAfis(productId, user.id)
      .then((response) => {
        if (response.data.result == 1) {
          //console.debug(response.data);
          setProduct({
            id: response.data.data.id,
            name: response.data.data.name,
            customer_id: response.data.data.customer_id,
          });
          AfiProvider.saveAfiLayout(response.data.data.afi_layout);
          AfiProvider.saveAfis(response.data.data.afis);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
    
    ListitemService.query("validations")
      .then((response) => {
        if (response.data.result == 1) {
          AfiProvider.saveValidations(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
    
  }, []);

  useEffect(() => {
    if (proof) {
      // debugger;
      // const el = document.getElementById("preview-" + product.id);
      // el.src = appConstant.BASE_URL + "/getProof/" + proof.code;
    }
  }, [proof]);

  /*
  const handleAfiChange = (ev) => {
    // debugger;
    AfiProvider.updateAfiValue(ev.target.name, "user_value", ev.target.value);
    setAfiValueChanged(afiValueChanged + 1);
  }
  */

  // Fade the preview in 4 seconds
  const beforeSend = () => {
    const el = document.getElementById("preview-box");
    el.style.opacity = 0;
    el.style.transition = "opacity 4s";
  };
  
  // Show the preview in 2 seconds
  const afterResponse = () => {
    const el = document.getElementById("preview-box");
    el.style.opacity = 1;
    el.style.transition = "opacity 2s";
  }

  const handlePreview = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    const rv = AfiProvider.validateForm(null);    
    if (rv.result == false) {
      return false;
    }

    const data = {
      customer_id: product.customer_id,
      user_id: user.id,
      product_id: product.id,
      // Reuse preorder Id
      preorder_id: proof? proof.preorder_id: null,
      session_id: null,
      quantity: 1,
      description_user_1: null,
      description_user_2: null,
      // Optional generate proof
      "proof": {
        "confirmation_email": 0,
        // use for: PRINT, PROOF, DOWNLOAD_DOCUMENT, DOWNLOAD_CAMPAIGN, PROOF_SET, RECORD_SET, EMAIL_MARKETING, EMAIL_PREFLIGHT
        "job_type": "PROOF",
        // output format:  PDF, PDFO, JPG, RECORDSET, PS, VIPP, PPML, VDX, JOB_INFO
        "output_type": "JPG",
        // 1 = print, 2 = web, 4 = email
        "media_type": 1,
        // user values
        "afis": AfiProvider.serialize(),
        "customs": null
      }
    }

    PreorderService.add(data, beforeSend, afterResponse)
      .then((response) => {
        if (response.data.result == 1) {
          SetProof(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error create proof:", error);
      });

    /* We migh do fade in and fade out
    const el = document.getElementById("preview-" + product.id);
    if (el) {
      el.src = appConstant.DOCUMENT_ROOT + "/dist/img/loading_spin.gif";
    }
    */
  }

  /**
   * Required callback function
   * @param string name 
   */
  const getCallback = (name) => {

  }

  if (product) {
    return (
      <section className="p-2">
        <h2 className="p-2 text-center">{product.name} </h2>
        <div className="row px-2 ">
          <div id="preview-box" className="col-7 text-center border p-2">
            {
              proof ? <ZoomBox proof={proof} />
                :
                <img id={"preview-" + product.id} src={"https://www.precisionservicesgroup.com/img/products/previews/" + product.id + ".jpg"}
                  className="img-fluid" style={{ "cursor": "pointer" }}
                />
            }
          </div>

          {
            AfiProvider.afis &&
            <div className="col-5">
                <AfiForm afiProvider={AfiProvider} getCallback={getCallback}  />
              <div className="px-2 mt-2 text-center">
                <button type="button" className="btn btn-primary" onClick={handlePreview} >Preview</button>
              </div>
            </div>
          }
        </div>

      </section>
    );
  }
  else {
    return (<div></div>);
  }
}
