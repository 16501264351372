import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from "react-toastify";
import appConstant from "constant/index";

/**
 * Show product detail page
 * 
 */
export default function Detail(props) {

  const location = useLocation();
  const navigate = useNavigate();
  const { getProfile, saveProfile, logout } = props.useSession();
  const [user, setUser] = useState(getProfile());

  const totalPoint = useMemo((user) => {
    let total = 0;
    user && user.rewards && user.rewards.map((reward) => {
      if (reward.action == "refer") {
        total = total + reward.point;
      }
      if (reward.action == "redeem") {
        total = total - reward.point;
      }
    });
    return total;
  }, [user]);

  useEffect(() => {
    document.title = 'Account';
  }, []);

  const handleImageError = (e) => {
    e.stopPropagation();
    e.target.src = appConstant.DOCUMENT_ROOT + '/dist/img/noimages.png'; // Provide the path to your default or empty image
  };

  const handleSignOut = () => {
    logout();
    document.location = "/";
  }

  const saveProfileAddress = (address) => {
    // debugger;
    let prev = getProfile();
    prev.profile.shipping_address = address;
    saveProfile(prev);
    setUser({ ...user, ["profile"]: prev.profile });
  }

  const saveProfileComm = (comm) => {
    // debugger;
    let prev = getProfile();
    prev.communication = comm;
    saveProfile(prev);
    setUser({ ...user, ["profile"]: prev.profile });
  }

  const saveProfileStatus = (status) => {
    // debugger;
    let prev = getProfile();
    prev.profile.status = status;
    saveProfile(prev);
    setUser({ ...user, ["profile"]: prev.profile });
  }

  // Array of string
  const saveProfilePets = (pets) => {
    // debugger;
    let prev = getProfile();
    prev.profile.pet_names = pets;
    saveProfile(prev);
    setUser({ ...user, ["profile"]: prev.profile });
  }

  return (
    <section className="mt-4 mx-2" style={{ "minHeight": "80vh" }}>
      {
        location.hash === "" && (
          <div className="px-5">
            <h1 className="fm-tiempos fw-bolder text-center my-5 fs-xlarge">Manage your account</h1>
            <div className="row justify-content-center">
              <div className="col-2 text-end">
                <img src={appConstant.DOCUMENT_ROOT + "/dist/img/icon-user.svg"} class="icon" width="18" alt="icon" />
              </div>
              <div className="col-10 fs-4">
                <a className="fw-bold text-black text-decoration-none" href="#profile" >Profile</a>
              </div>
            </div>

            <div className="row my-4 justify-content-center">
              <div className="col-2 text-end">
                <img src={appConstant.DOCUMENT_ROOT + "/dist/img/icon-pig.svg"} class="icon" width="25" alt="icon" />
              </div>
              <div className="col-10 fs-4">
                <a className="fw-bold text-black text-decoration-none" href="#history">Rewards History</a>
              </div>
            </div>

            <div className="row my-4 justify-content-center">
              <div className="col-2 text-end">
                <img src={appConstant.DOCUMENT_ROOT + "/dist/img/icon-chat.svg"} class="icon" width="25" alt="icon" />
              </div>
              <div className="col-10 fs-4">
                <a className="fw-bold text-black text-decoration-none" href="#communication">Communication & privacy</a>
              </div>
            </div>
            <div className="row my-4 justify-content-center">
              <div className="col-2 text-end">
                <img src={appConstant.DOCUMENT_ROOT + "/dist/img/icon-help.svg"} class="icon" width="25" alt="icon" />
              </div>
              <div className="col-10 fs-4">
                <a className="fw-bold text-black text-decoration-none" href="#help">Help</a>
              </div>
            </div>
            <div className="row my-4 justify-content-center">
              <div className="col-2 text-end">
                <img src={appConstant.DOCUMENT_ROOT + "/dist/img/icon-logout.svg"} class="icon" width="18" alt="icon" />
              </div>
              <div className="col-10 fs-4">
                <a className="fw-bold text-black text-decoration-none" href="#" onClick={handleSignOut}>Sign out</a>
              </div>
            </div>
          </div>
        )
      }
      {
        location.hash === "#profile" && <Profile user={user} saveProfilePets={saveProfilePets} saveProfileAddress={saveProfileAddress} />
      }
      {
        location.hash === "#history" && <History user={user} />
      }
      {
        location.hash === "#communication" && <Communication user={user} saveProfileComm={saveProfileComm} />
      }
      {
        location.hash === "#suspend" && <Suspend user={user} saveProfileStatus={saveProfileStatus} />
      }
      {
        location.hash === "#help" && <Help user={user} />
      }
      {
        location.hash === "#programrules" && <ProgramRules />
      }
      {
        location.hash === "#privacypolicy" && <PrivacyPolicy />
      }
    </section>
  );

}

